import { Dialog, KOIcon } from "design-system";
import { option, array } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { Task } from "fp-ts/Task";
import { ComponentProps } from "react";
import { useFormatMessage } from "../intl";

type Props = {
  onUploadAgain: Option<Task<unknown>>;
  onContinue: Task<unknown>;
};

export function RedoFraudCheckDialog(props: Props) {
  const formatMessage = useFormatMessage();

  type Actions = ComponentProps<typeof Dialog>["actions"];

  const uploadAgainAction = pipe(
    props.onUploadAgain,
    option.map(
      action =>
        ({
          variant: "secondary",
          label: formatMessage("Identification.UploadDocuments.uploadAgain"),
          action,
        } as Actions[0])
    )
  );

  const confirmAction = option.some({
    variant: "primary",
    label: formatMessage(
      "Identification.UploadDocuments.MobileUploadStatusModal.redoFraudCheckConfirm"
    ),
    action: props.onContinue,
  } as Actions[0]);

  const actions = array.compact([uploadAgainAction, confirmAction]) as Actions;

  return (
    <Dialog
      icon={KOIcon}
      variant="center"
      size="medium"
      title={formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.redoFraudCheckTitle"
      )}
      subtitle={formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.redoFraudCheckMessage"
      )}
      onDismiss={option.none}
      actions={actions}
    />
  );
}
