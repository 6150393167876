import {
  Box,
  ComputedFieldProps,
  DropdownOption,
  Space,
  unsafeLocalizedString,
} from "design-system";
import { constNull, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { Countries } from "./Countries";
import { DocumentTypes } from "./DocumentTypes";
import { CountryFormState } from "../UploadDocuments";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";
import { Country } from "../domain";
import { useFormatMessage } from "../../intl";
import { IO } from "fp-ts/IO";
import { formatDocumentType } from "../utils";

export type Props = {
  localCountryCode: NonEmptyString;
  fieldProps: <K extends keyof CountryFormState>(
    name: K
  ) => ComputedFieldProps<CountryFormState[K]>;
  handleReset: IO<void>;
  onNoCountries: () => unknown;
  onDocTypeChange?: (type: Option<"IDCard" | "Passport">) => unknown;
  onCountryChange?: (country: Option<Country>) => unknown;
  restoredData?: RestoreApplicationData;
  disabled: boolean;
};

export function ChooseDocumentToUpload(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <Box column grow shrink>
      <Countries
        onSelectionChange={newSelection => {
          props.fieldProps("country").onChange(newSelection);
          if (props.onCountryChange) {
            props.onCountryChange(newSelection);
          }
          props.fieldProps("docType").onChange(option.none);
        }}
        issues={props.fieldProps("country").issues}
        onNoCountries={props.onNoCountries}
        localCountryCode={props.localCountryCode}
        restoredData={props.restoredData}
        disabled={props.disabled}
        handleReset={props.handleReset}
      />
      <Space units={4} />
      {pipe(
        props.fieldProps("country").value,
        option.fold(constNull, country =>
          country.countryCode === props.localCountryCode ? null : (
            <DocumentTypes
              {...props.fieldProps("docType")}
              label={formatMessage(
                "Identification.UploadDocuments.documentType.label"
              )}
              value={pipe(
                props.fieldProps("docType").value,
                option.map(
                  currentValue =>
                    ({
                      value: currentValue,
                      label: formatDocumentType(currentValue),
                    } as DropdownOption<"IDCard" | "Passport">)
                )
              )}
              name={unsafeLocalizedString("name")}
              placeholder={formatMessage(
                "Identification.UploadDocuments.documentType.placeholder"
              )}
              clearable={false}
              country={country}
              onSelectionChange={type => {
                props.fieldProps("docType").onChange(option.some(type));
                if (props.onDocTypeChange) {
                  props.onDocTypeChange(option.some(type));
                }
              }}
              disabled={props.disabled}
            />
          )
        )
      )}
    </Box>
  );
}
