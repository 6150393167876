import {
  Banner,
  Body,
  Box,
  Button,
  CheckboxField,
  Divider,
  LightIcon,
  Space,
  Stack,
  unsafeLocalizedString,
} from "design-system";
import * as classes from "../../UploadId.treat";
import { palette } from "design-system/lib/styleConstants";
import { option } from "fp-ts";
import {
  DocumentIdentificationFlow,
  UploadDocumentFlowType,
} from "../../../globalDomain";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import { useState } from "react";
import { useTestEnvironment } from "../../../Common/useTestEnvironment";
import { MobileRequiredDocuments } from "../common/MobileRequiredDocuments";

export type WhatToUploadProps = {
  hasPrimaryDocuments: boolean;
  hasSecondaryDocuments: boolean;
  productType: Option<UploadDocumentFlowType>;
  documentIdentificationFlow: DocumentIdentificationFlow;
  biometricConsent: Option<boolean>;
  mustGiveConsent: boolean;
  onBiometricConsent: (positive: boolean, mockScanner: boolean) => unknown;
};

export default function WhatToUpload(props: WhatToUploadProps) {
  const formatMessage = useFormatMessage();
  const isTestEnvironment = useTestEnvironment();
  const shouldBlockUser = props.mustGiveConsent;

  const [negativeConsentPressed, setNegativeConsentPressed] = useState(false);
  const blockUserError = shouldBlockUser && negativeConsentPressed && (
    <>
      <Space units={5} />
      <Banner
        type="error"
        actions={option.none}
        content={formatMessage(
          "Identification.UploadDocuments.step1.biometricConsentRequiredError"
        )}
        title={option.none}
      />
    </>
  );

  const isPersonalProfile = pipe(
    props.productType,
    option.exists(value => value === "PersonalProfile")
  );

  const [mockScanner, setMockScanner] = useState(false);

  return (
    <Box
      column
      shrink
      grow
      style={isPersonalProfile ? { padding: 24 } : undefined}
    >
      <Body size={"medium"} weight={"medium"}>
        {formatMessage("Identification.UploadDocuments.mobile.whatToScanTitle")}
      </Body>
      <Space units={4} />
      <Body size={"small"} weight={"regular"}>
        {formatMessage("Identification.UploadDocuments.mobile.whatToScanText1")}
      </Body>
      <Space units={4} />
      <Box grow shrink column>
        <MobileRequiredDocuments {...props} />
      </Box>
      <Space units={8} />
      <Divider className={classes.divider} />
      <Space units={4} />
      <Stack width={"100%"} units={4}>
        <Box hAlignContent={"center"} vAlignContent={"center"}>
          <LightIcon
            size={"large"}
            color={palette.blue600}
            className={classes.hintIcon}
          />
        </Box>
        <Box grow shrink>
          <Body size={"medium"} weight={"medium"}>
            {formatMessage(
              "Identification.UploadDocuments.mobile.whatToScanHint"
            )}
          </Body>
        </Box>
      </Stack>
      <Space units={4} />
      <Divider className={classes.divider} />
      <Space units={8} />
      <Body size={"small"} weight={"regular"}>
        {formatMessage("Identification.UploadDocuments.mobile.whatToScanText2")}
      </Body>
      <Space units={2} />
      {isTestEnvironment && (
        <>
          <CheckboxField
            name={"MockScannerCheck"}
            label={unsafeLocalizedString("Proceed with mock scanner")}
            issues={option.none}
            onChange={setMockScanner}
            value={mockScanner}
          />
          <Space units={4} />
        </>
      )}
      <Button
        label={formatMessage(
          "Identification.UploadDocuments.mobile.whatToScanPositiveConsentButton"
        )}
        variant="primary"
        size="default"
        action={() => {
          props.onBiometricConsent(true, mockScanner);
        }}
      />
      <Space units={2} />
      <Button
        label={formatMessage(
          "Identification.UploadDocuments.mobile.whatToScanNegativeConsentButton"
        )}
        variant="text"
        size="default"
        action={() =>
          shouldBlockUser
            ? setNegativeConsentPressed(true)
            : props.onBiometricConsent(false, mockScanner)
        }
      />
      {blockUserError}
    </Box>
  );
}
