import { ComponentProps, useEffect, useState } from "react";
import { useCommand, usePollingEffect } from "../useAPI";
import * as uploadApi from "./api";
import {
  Button,
  Banner,
  Box,
  Body,
  Space,
  MobileUploadIDIcon,
  MobileIcon,
  MobileConnectionOKIcon,
  MobileConnectionKOIcon,
  Dialog,
  AlertDialog,
  ProblemIcon,
} from "design-system";
import {
  constant,
  constNull,
  constTrue,
  constVoid,
  pipe,
} from "fp-ts/function";
import { taskEither } from "fp-ts";
import { useFormatMessage } from "../intl";
import * as option from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import { CoApplicantInput, DocumentIdentificationFlow } from "../globalDomain";
import { Task } from "fp-ts/Task";
import { LinkPurpose } from "./domain";
import { MobileFlowType, MobileRecipientOptionType } from "./state";
import { foldMobileFlowType } from "./utils";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

type Props = {
  flowType: MobileFlowType;
  recipient: MobileRecipientOptionType;
  onDismiss: () => unknown;
  onMaxAttemptsReached: () => unknown;
  onUploadCompleted: Task<unknown>;
  documentIdentificationFlow: DocumentIdentificationFlow;
  docTypeId: Option<NonEmptyString>;
  applicationElementID: Option<NonEmptyString>;
} & CoApplicantInput;

interface WaitingForConnectionStage {
  name: "WaitingForConnection";
  attemptsBannerMessage: Option<"LastLink">;
  resendLinkTimeout: Option<number>;
  hasSentLinkError: boolean;
  hasSentLink: boolean;
}

interface MobileConnectedStage {
  name: "MobileConnected";
}

interface UploadInProgressStage {
  name: "UploadInProgress";
}

interface ConnectionLostStage {
  name: "ConnectionLost";
}

interface IsCancellingProcessStage {
  name: "IsCancellingProcess";
  last_stage: MobileModalStage;
}

interface MaxAttemptsReached {
  name: "MaxAttemptsReached";
}

type MobileModalStage =
  | WaitingForConnectionStage
  | MobileConnectedStage
  | UploadInProgressStage
  | ConnectionLostStage
  | IsCancellingProcessStage
  | MaxAttemptsReached;

const RESEND_LINK_TIMEOUT = 60000;

function foldStage<T>(
  stage: MobileModalStage,
  match: {
    whenWaitingForConnection: (stage: WaitingForConnectionStage) => T;
    whenMobileConnected: (stage: MobileConnectedStage) => T;
    whenUploadInProgress: (stage: UploadInProgressStage) => T;
    whenConnectionLost: (stage: ConnectionLostStage) => T;
    whenCancellingProcess: (stage: IsCancellingProcessStage) => T;
    whenMaxAttemptsReached: (stage: MaxAttemptsReached) => T;
  }
) {
  switch (stage.name) {
    case "WaitingForConnection":
      return match.whenWaitingForConnection(stage);
    case "MobileConnected":
      return match.whenMobileConnected(stage);
    case "UploadInProgress":
      return match.whenUploadInProgress(stage);
    case "ConnectionLost":
      return match.whenConnectionLost(stage);
    case "IsCancellingProcess":
      return match.whenCancellingProcess(stage);
    case "MaxAttemptsReached":
      return match.whenMaxAttemptsReached(stage);
  }
}

function shouldUpdate(stage: MobileModalStage): boolean {
  return foldStage(stage, {
    whenWaitingForConnection: () => false,
    whenMobileConnected: () => false,
    whenUploadInProgress: () => false,
    whenConnectionLost: () => false,
    whenCancellingProcess: () => true,
    whenMaxAttemptsReached: () => true,
  });
}

const initialMobileModalStage: MobileModalStage = {
  name: "WaitingForConnection",
  attemptsBannerMessage: option.none,
  resendLinkTimeout: option.none,
  hasSentLinkError: false,
  hasSentLink: false,
};

export function MobileUploadStatusDialogPOI(props: Props) {
  const formatMessage = useFormatMessage();
  const sendLink = useCommand(uploadApi.sendMobileLinkPOI);
  const cancelProcess = useCommand(uploadApi.cancelProcess);

  const [stage, setStage] = useState(initialMobileModalStage);

  function transition(
    getNextStage: (currentStage: MobileModalStage) => MobileModalStage
  ): void {
    setStage(currentStage => {
      const nextStage = getNextStage(currentStage);

      if (currentStage.name !== nextStage.name) {
        if (
          currentStage.name === "WaitingForConnection" &&
          nextStage.name !== "WaitingForConnection" &&
          option.isSome(currentStage.resendLinkTimeout)
        ) {
          clearTimeout(currentStage.resendLinkTimeout.value);
        }
      }

      if (shouldUpdate(currentStage)) {
        return { ...currentStage, last_stage: nextStage };
      } else {
        return nextStage;
      }
    });
  }

  const onSendLink = (isFirstTime: boolean) => {
    if (stage.name !== "WaitingForConnection") {
      return;
    }

    pipe(
      sendLink({
        recipient: props.recipient.key,
        linkPurpose: foldMobileFlowType<LinkPurpose>(props.flowType, {
          DocumentUpload: constant("mobileIdUpload"),
          SelfieFraudCheck: constant("selfieFraudCheck"),
          HologramFraudCheck: constant("hologramFraudCheck"),
          HologramAndSelfieFraudCheck: constant("hologramAndSelfieFraudCheck"),
          ProofOfIncome: constant("proofOfIncome"),
        }),
        idType: props.documentIdentificationFlow,
        coApplicant: props.coApplicant,
        parameters: {
          docTypeId: props.docTypeId,
          applicationElementID: props.applicationElementID,
        },
      }),
      taskEither.bimap(
        error => {
          if (error.id !== "MaxLinksAttemptsReached") {
            transition(stage => ({
              ...stage,
              hasSentLinkError: true,
              hasSentLink: false,
            }));

            return;
          }
          setStage({ name: "MaxAttemptsReached" });
          props.onMaxAttemptsReached();
        },
        ({ attemptsLeft }) => {
          transition(stage =>
            stage.name !== "WaitingForConnection"
              ? stage
              : {
                  ...stage,
                  hasSentLinkError: false,
                  hasSentLink: true,
                  attemptsBannerMessage:
                    attemptsLeft === 0 ? option.some("LastLink") : option.none,
                  resendLinkTimeout: isFirstTime
                    ? option.none
                    : option.some(
                        window.setTimeout(
                          () =>
                            transition(stage =>
                              stage.name !== "WaitingForConnection"
                                ? stage
                                : {
                                    ...stage,
                                    resendLinkTimeout: option.none,
                                  }
                            ),
                          RESEND_LINK_TIMEOUT
                        )
                      ),
                }
          );
        }
      )
    )();
  };

  const onCancellingProcess = (
    old_stage: Exclude<MobileModalStage, IsCancellingProcessStage>
  ) => () => {
    transition(() => ({
      name: "IsCancellingProcess",
      last_stage: old_stage,
    }));
  };

  const onCancelProcess = () =>
    pipe(
      cancelProcess(),
      taskEither.bimap(
        () =>
          transition(() => ({
            name: "ConnectionLost",
          })),
        () => props.onDismiss()
      )
    )();

  const [pollingResponseReceived, setPollingResponseReceived] = useState(false);
  const pollingDisabled =
    stage.name === "ConnectionLost" ||
    stage.name === "IsCancellingProcess" ||
    (stage.name === "WaitingForConnection" && !stage.hasSentLink);

  const refreshPolling = usePollingEffect(
    uploadApi.mobileStatus,
    {
      disabled: pollingDisabled || pollingResponseReceived,
      intervalMS: 5000,
      shouldPollingContinue: constTrue,
      onError: () => transition(() => ({ name: "ConnectionLost" })),
      onSuccess: ({ status }) => {
        if (status === "WaitingForConnection") {
          transition(stage =>
            stage.name === "WaitingForConnection"
              ? stage
              : initialMobileModalStage
          );
        } else if (
          status === "UploadCompleted" ||
          status === "MustEditData" ||
          status === "VerificationCompleted"
        ) {
          setPollingResponseReceived(true);
          props.onUploadCompleted();
        } else {
          transition(() => ({ name: status }));
        }
      },
    },
    { coApplicant: props.coApplicant }
  );
  const [pollingRunning, setPollingRunning] = useState(false);
  useEffect(() => {
    if (pollingDisabled) {
      setPollingRunning(false);
    }
    if (!pollingDisabled && !pollingRunning) {
      refreshPolling();
      setPollingRunning(true);
    }
  }, [pollingDisabled, refreshPolling, pollingRunning]);

  useEffect(() => {
    if (stage.name === "WaitingForConnection") {
      onSendLink(true);
    }
  }, []);

  const modalTitle = foldStage(stage, {
    whenWaitingForConnection: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.waitingTitle"
      ),
    whenMobileConnected: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.connectedTitle"
      ),
    whenUploadInProgress: () =>
      formatMessage(
        foldMobileFlowType(props.flowType, {
          DocumentUpload: constant(
            "Identification.UploadDocuments.MobileUploadStatusModal.uploadingTitle"
          ),
          ProofOfIncome: constant(
            "Identification.UploadDocuments.MobileUploadStatusModal.uploadingProofOfIncomeTitle"
          ),
          SelfieFraudCheck: constant(
            "Identification.UploadDocuments.MobileUploadStatusModal.uploadingSelfieCheckTitle"
          ),
          HologramFraudCheck: constant(
            "Identification.UploadDocuments.MobileUploadStatusModal.uploadingFraudCheckTitle"
          ),
          HologramAndSelfieFraudCheck: constant(
            "Identification.UploadDocuments.MobileUploadStatusModal.uploadingFraudCheckTitle"
          ),
        })
      ),
    whenConnectionLost: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.errorTitle"
      ),
    whenCancellingProcess: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.exitingTitle"
      ),
    whenMaxAttemptsReached: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.maxAttemptsLimitErrorTitle"
      ),
  });

  const modalContent = foldStage(stage, {
    whenWaitingForConnection: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.waitingDescription",
        { phoneNumber: props.recipient.phoneNumber }
      ),
    whenMobileConnected: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.connectedDescription"
      ),
    whenUploadInProgress: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.uploadingDescription"
      ),
    whenConnectionLost: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.errorDescription"
      ),
    whenMaxAttemptsReached: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.maxAttemptsLimitError"
      ),
    whenCancellingProcess: () =>
      formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.exitingDescription"
      ),
  });

  const transitionToCancelling = (stage: MobileModalStage) => {
    if (stage.name !== "IsCancellingProcess") {
      return onCancellingProcess(stage);
    } else {
      return constVoid;
    }
  };

  const recoverFromCancelling = (cancellingStage: IsCancellingProcessStage) => {
    setStage(cancellingStage.last_stage);
  };

  const banner = (
    <Banner
      type="informative"
      title={option.none}
      content={formatMessage(
        "Identification.UploadDocuments.MobileUploadStatusModal.bannerContent"
      )}
      actions={option.none}
      onDismiss={option.none}
    />
  );

  const icon = foldStage(stage, {
    whenWaitingForConnection: constant(MobileIcon),
    whenMobileConnected: constant(MobileConnectionOKIcon),
    whenUploadInProgress: constant(MobileUploadIDIcon),
    whenConnectionLost: constant(MobileConnectionKOIcon),
    whenCancellingProcess: constant(MobileIcon), // TODO(gabro): double check this
    whenMaxAttemptsReached: constant(ProblemIcon),
  });

  const optionalBanner = foldStage(stage, {
    whenWaitingForConnection: () => option.some(banner),
    whenMobileConnected: () => option.some(banner),
    whenUploadInProgress: () => option.some(banner),
    whenConnectionLost: () => option.none,
    whenCancellingProcess: () => option.none,
    whenMaxAttemptsReached: () => option.none,
  });

  const cancelAction = {
    variant: "text",
    action: transitionToCancelling(stage),
    label: formatMessage(
      "Identification.UploadDocuments.MobileUploadStatusModal.cancelAction"
    ),
  } as const;

  const actions = foldStage<ComponentProps<typeof Dialog>["actions"]>(stage, {
    whenWaitingForConnection: constant([]),
    whenMobileConnected: constant([cancelAction]),
    whenUploadInProgress: constant([cancelAction]),
    whenConnectionLost: constant([
      {
        variant: "primary",
        action: props.onDismiss,
        label: formatMessage(
          "Identification.UploadDocuments.MobileUploadStatusModal.closeAction"
        ),
      },
    ]),
    // these are handled separately, see below
    whenCancellingProcess: constant([]),
    whenMaxAttemptsReached: constant([
      {
        variant: "primary",
        action: props.onDismiss,
        label: formatMessage(
          "Identification.UploadDocuments.MobileUploadStatusModal.closeAction"
        ),
      },
    ]),
  });

  const bottomContent = foldStage(stage, {
    whenWaitingForConnection: stage =>
      option.some(
        <>
          <Box hAlignContent="center" vAlignContent="center">
            <Body size="small" weight="regular">
              {formatMessage(
                "Identification.UploadDocuments.MobileUploadStatusModal.waitingResendLabel"
              )}
            </Body>
            <Space units={3} />
            <Button
              variant="link"
              action={() => onSendLink(false)}
              label={formatMessage(
                "Identification.UploadDocuments.MobileUploadStatusModal.waitingResendAction"
              )}
              disabled={option.isSome(stage.resendLinkTimeout)}
            />
          </Box>
          <Space units={5} />
          {option.isSome(stage.resendLinkTimeout) &&
          !pipe(
            stage.attemptsBannerMessage,
            option.exists(
              attemptsBannerMessage => attemptsBannerMessage === "LastLink"
            )
          ) ? (
            <Banner
              type="success"
              title={option.none}
              content={formatMessage(
                "Identification.UploadDocuments.MobileUploadStatusModal.linkResent"
              )}
              actions={option.none}
              onDismiss={option.none}
            />
          ) : null}
          {pipe(
            stage.attemptsBannerMessage,
            option.fold(constNull, attemptsBannerMessage => {
              switch (attemptsBannerMessage) {
                case "LastLink":
                  return (
                    <>
                      <Space units={3} />
                      <Banner
                        type="warning"
                        title={option.none}
                        content={formatMessage(
                          "Identification.UploadDocuments.MobileUploadStatusModal.lastAttempt"
                        )}
                        actions={option.none}
                        onDismiss={option.none}
                      />
                    </>
                  );
              }
            })
          )}
        </>
      ),
    whenMobileConnected: () => option.none,
    whenUploadInProgress: () => option.none,
    whenConnectionLost: () => option.none,
    whenCancellingProcess: () => option.none,
    whenMaxAttemptsReached: () => option.none,
  });

  switch (stage.name) {
    case "IsCancellingProcess":
      return (
        <AlertDialog
          type="disruptive"
          title={modalTitle}
          message={modalContent}
          cancelLabel={formatMessage(
            "Identification.UploadDocuments.MobileUploadStatusModal.exitingCancel"
          )}
          onDismiss={() => recoverFromCancelling(stage)}
          onCancel={() => recoverFromCancelling(stage)}
          confirmLabel={formatMessage(
            "Identification.UploadDocuments.MobileUploadStatusModal.exitingConfirmation"
          )}
          onConfirm={onCancelProcess}
        />
      );
    case "MaxAttemptsReached":
    case "WaitingForConnection":
    case "MobileConnected":
    case "ConnectionLost":
    case "UploadInProgress":
      return (
        <Dialog
          variant="center"
          size="medium"
          onDismiss={option.some(
            stage.name === "ConnectionLost"
              ? props.onDismiss
              : transitionToCancelling(stage)
          )}
          title={modalTitle}
          subtitle={modalContent}
          icon={icon}
          actions={actions}
        >
          {pipe(optionalBanner, option.toNullable)}
          <Space units={5} />
          {pipe(bottomContent, option.toNullable)}
        </Dialog>
      );
  }
}
