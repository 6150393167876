import { FeedbackBlock, ValueOf } from "design-system";
import { option } from "fp-ts";
import { CameraError } from "./zenidUtils";
import { MobileUploadStatusError } from "../api";
import { useFormatMessage, LocaleKey } from "../../intl";
import { useAppRedirect } from "../../Common/useSBDeepLink";

export const MobileUploadState = {
  Completed: "Completed" as const,
  Rejected: "Rejected" as const,
  RejectedByUser: "RejectedByUser" as const,
  CameraError: "CameraError" as const,
  CameraDisabled: "CameraDisabled" as const,
  CameraUnavailable: "CameraUnavailable" as const,
  UploadError: "UploadError" as const,
  DocumentInvalid: "DocumentInvalid" as const,
  DocumentCountryInvalid: "DocumentCountryInvalid" as const,
  PageInvalid: "PageInvalid" as const,
  ConnectionLost: "ConnectionLost" as const,
};

export const MobileUploadStateSpec: MobileUploadStateSpec = {
  Completed: {
    type: "positive",
    heading: "Identification.UploadDocuments.mobile.successTitle",
    subheading: "Identification.UploadDocuments.mobile.successDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  Rejected: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobile.rejectTitle",
    subheading: "Identification.UploadDocuments.mobile.rejectDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  RejectedByUser: {
    type: "negative",
    heading:
      "Identification.UploadDocuments.proofOfIncome.rejectedByUser.title",
    subheading:
      "Identification.UploadDocuments.proofOfIncome.rejectedByUser.subtitle",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  CameraError: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobileCheck.cameraErrorTitle",
    subheading:
      "Identification.UploadDocuments.mobileCheck.cameraErrorDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  CameraDisabled: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobileCheck.cameraDisabledTitle",
    subheading:
      "Identification.UploadDocuments.mobileCheck.cameraDisabledDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  CameraUnavailable: {
    type: "negative",
    heading:
      "Identification.UploadDocuments.mobileCheck.cameraUnavailableTitle",
    subheading:
      "Identification.UploadDocuments.mobileCheck.cameraUnavailableDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  UploadError: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobile.uploadErrorTitle",
    subheading: "Identification.UploadDocuments.mobile.uploadErrorDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  DocumentInvalid: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobile.invalidDocumentTitle",
    subheading:
      "Identification.UploadDocuments.mobile.invalidDocumentDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  DocumentCountryInvalid: {
    type: "negative",
    heading:
      "Identification.UploadDocuments.mobile.invalidDocumentCountryTitle",
    subheading:
      "Identification.UploadDocuments.mobile.invalidDocumentCountryDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  PageInvalid: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobile.invalidPageTitle",
    subheading: "Identification.UploadDocuments.mobile.invalidPageDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
  ConnectionLost: {
    type: "negative",
    heading: "Identification.UploadDocuments.mobile.connectionLostTitle",
    subheading:
      "Identification.UploadDocuments.mobile.connectionLostDescription",
    content: "Identification.UploadDocuments.mobile.canCloseWindow",
  },
};

export function MobileUploadFeedbackBanner(
  props: MobileUploadFeedbackBannerProps
) {
  const { state } = props;
  const formatMessage = useFormatMessage();
  const spec = MobileUploadStateSpec[state] || MobileUploadState.UploadError;
  const redirectToMobileApp = useAppRedirect();

  return (
    <FeedbackBlock
      type={spec.type}
      size="large"
      heading={formatMessage(spec.heading)}
      subheading={option.some(formatMessage(spec.subheading))}
      actions={
        props.showOpenMobileAppButton
          ? [
              {
                variant: "primary",
                label: formatMessage(
                  "Identification.UploadDocuments.mobile.returnToApp"
                ),
                action: redirectToMobileApp,
              },
            ]
          : []
      }
      banner={{
        type: "informative",
        actions: option.none,
        content: formatMessage(spec.content),
        onDismiss: option.none,
        title: option.none,
      }}
    />
  );
}

export function fromCameraErrorToFeedbackState(error: CameraError) {
  switch (error) {
    case "generic":
      return MobileUploadState.CameraError;
    case "disabled":
      return MobileUploadState.CameraDisabled;
    case "unavailable":
      return MobileUploadState.CameraUnavailable;
  }
}

export function fromUploadErrorToFeedbackState(error: MobileUploadStatusError) {
  switch (error) {
    case "GenericError":
      return MobileUploadState.ConnectionLost;
    case "InvalidDocument":
      return MobileUploadState.DocumentInvalid;
    case "InvalidDocumentCountry":
      return MobileUploadState.DocumentCountryInvalid;
    case "InvalidPage":
      return MobileUploadState.PageInvalid;
  }
}

export interface MobileUploadFeedbackBannerProps {
  state: MobileUploadState;
  showOpenMobileAppButton?: boolean;
}

export type MobileUploadState = ValueOf<typeof MobileUploadState>;

export type MobileUploadStateSpec = {
  [key in keyof typeof MobileUploadState]: {
    type: "positive" | "negative";
    heading: LocaleKey;
    subheading: LocaleKey;
    content: LocaleKey;
  };
};
