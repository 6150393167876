import {
  Box,
  Space,
  Body,
  ErrorIcon,
  SuccessIcon,
  Panel,
  Heading,
  Stack,
  UnorderedList,
  LocalizedString,
  useIsMobileLayout,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { useFormatMessage } from "../../intl";

import czPrimaryDarkImage from "./czPrimaryDark.png";
import czPrimaryGlareImage from "./czPrimaryGlare.png";
import czPrimaryImage from "./czPrimary.png";

import czSecondaryDarkImage from "./czSecondaryDark.png";
import czSecondaryGlareImage from "./czSecondaryGlare.png";
import czSecondaryImage from "./czSecondary.png";

import skPrimaryDarkImage from "./skPrimaryDark.png";
import skPrimaryGlareImage from "./skPrimaryGlare.png";
import skPrimaryImage from "./skPrimary.png";

import skSecondaryDarkImage from "./skSecondaryDark.png";
import skSecondaryGlareImage from "./skSecondaryGlare.png";
import skSecondaryImage from "./skSecondary.png";

import livenesscheckOkImage from "./livenesscheck_ok.png";
import livenesscheckDarkImage from "./livenesscheck_dark.png";
import livenesscheckPartialImage from "./livenesscheck_partial.png";
import livenesscheckSunglassesImage from "./livenesscheck_sunglasses.png";

import idCardCZ from "./../idcard_cz.png";
import idCardSK from "./../idcard_sk.png";
import idCardBackCZ from "./../idcard_back_cz.png";
import idCardBackSK from "./../idcard_back_sk.png";
import drivingLicenseCZ from "./../drivinglicense_cz.png";
import drivingLicenseSK from "./../drivinglicense_sk.png";
import passportCZ from "./../passport_cz.png";
import passportSK from "./../passport_sk.png";

import { StartProcess } from "./StartProcess";
import { useAppContext } from "../../useAppContext";
import { DocumentIdentificationFlow, Tenant } from "../../globalDomain";
import { useState } from "react";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { constant, constUndefined, pipe } from "fp-ts/function";
import { HelpLineSupportDocumentId } from "../../Common/HelplineSupportDocumentId/HelplineSupportDocumentId";

type Props = {
  onContinue: () => unknown;
  documentIdentificationFlow: DocumentIdentificationFlow;
  isProofOfIncome?: boolean;
};

function ExampleDocument(props: {
  image: string;
  imageAlt: LocalizedString;
  description: Option<LocalizedString>;
}) {
  return (
    <Box column>
      <img src={props.image} alt={props.imageAlt} height={100} />
      <Space units={2} />
      {pipe(
        props.description,
        option.fold(constUndefined, description => (
          <UnorderedList size="medium" listStyle="bullet">
            {[description]}
          </UnorderedList>
        ))
      )}
    </Box>
  );
}

function HowToDescription(props: {
  image: string;
  imageAlt: LocalizedString;
  isBad?: boolean;
  description: LocalizedString;
}) {
  return (
    <Box>
      <img src={props.image} alt={props.imageAlt} height={100} />
      <Space units={2} />
      <Box column shrink>
        {props.isBad ? (
          <ErrorIcon size="medium" color={palette.error900} />
        ) : (
          <SuccessIcon size="medium" color={palette.success800} />
        )}
        <Space units={2} />
        <Body size="medium" weight="regular">
          {props.description}
        </Body>
      </Box>
    </Box>
  );
}

export function StartIdProcess(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const [howToIsOpen, setHowToIsOpen] = useState(false);

  const isMobileLayout = useIsMobileLayout();

  const isPrimaryDocument =
    props.documentIdentificationFlow === "Primary" ||
    props.documentIdentificationFlow === "PrimaryAndSecondary" ||
    props.documentIdentificationFlow === "PrimaryAndLivenessCheck";

  const idCards = (() => {
    switch (tenant) {
      case "CZ":
        return {
          idCardFront: idCardCZ,
          idCardBack: idCardBackCZ,
        };
      case "SK":
        return {
          idCardFront: idCardSK,
          idCardBack: idCardBackSK,
        };
    }
  })();

  const passport = (() => {
    switch (tenant) {
      case "CZ":
        return passportCZ;
      case "SK":
        return passportSK;
    }
  })();

  const drivingLicense = (() => {
    switch (tenant) {
      case "CZ":
        return drivingLicenseCZ;
      case "SK":
        return drivingLicenseSK;
    }
  })();

  const imageSet = (tenant: Tenant) => {
    switch (tenant) {
      case "CZ":
        if (isPrimaryDocument) {
          return {
            normal: czPrimaryImage,
            dark: czPrimaryDarkImage,
            glare: czPrimaryGlareImage,
          };
        } else {
          return {
            normal: czSecondaryImage,
            dark: czSecondaryDarkImage,
            glare: czSecondaryGlareImage,
          };
        }
      case "SK":
        if (isPrimaryDocument) {
          return {
            normal: skPrimaryImage,
            dark: skPrimaryDarkImage,
            glare: skPrimaryGlareImage,
          };
        } else {
          return {
            normal: skSecondaryImage,
            dark: skSecondaryDarkImage,
            glare: skSecondaryGlareImage,
          };
        }
    }
  };

  const livenessCheckImageSet = {
    normal: livenesscheckOkImage,
    dark: livenesscheckDarkImage,
    partial: livenesscheckPartialImage,
    sunglasses: livenesscheckSunglassesImage,
  };

  const howToSection = (
    <Stack column units={3}>
      <HowToDescription
        image={imageSet(tenant).normal}
        imageAlt={formatMessage(
          "Identification.UploadDocuments.mobile.howToReadable"
        )}
        description={formatMessage(
          "Identification.UploadDocuments.mobile.howToReadableDescription"
        )}
      />
      <HowToDescription
        image={imageSet(tenant).glare}
        imageAlt={formatMessage(
          "Identification.UploadDocuments.mobile.howToNoGlare"
        )}
        description={formatMessage(
          "Identification.UploadDocuments.mobile.howToNoGlareDescription"
        )}
        isBad
      />
      <HowToDescription
        image={imageSet(tenant).dark}
        imageAlt={formatMessage(
          "Identification.UploadDocuments.mobile.howToNotTooDark"
        )}
        description={formatMessage(
          "Identification.UploadDocuments.mobile.howToNotTooDarkDescription"
        )}
        isBad
      />
    </Stack>
  );

  const primaryDocumentsExample = (
    <Stack column grow shrink units={5}>
      <Heading size="small" weight="medium">
        {formatMessage("Identification.UploadDocuments.mobile.primary")}
      </Heading>
      <Stack shrink fluid>
        <ExampleDocument
          image={idCards.idCardFront}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.idCardFront"
          )}
          description={option.some(
            formatMessage("Identification.UploadDocuments.mobile.idCardFront")
          )}
        />
        <ExampleDocument
          image={idCards.idCardBack}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.idCardBack"
          )}
          description={option.some(
            formatMessage("Identification.UploadDocuments.mobile.idCardBack")
          )}
        />
      </Stack>
    </Stack>
  );

  const secondaryDocumentsExample = (
    <Stack column grow shrink units={5}>
      <Heading size="small" weight="medium">
        {formatMessage("Identification.UploadDocuments.mobile.secondary")}
      </Heading>

      <Stack column units={2}>
        <Heading size="x-small" weight="regular">
          {formatMessage(
            "Identification.UploadDocuments.mobile.secondary.validDocuments"
          )}
        </Heading>

        <UnorderedList size="medium" listStyle="bullet">
          {[
            formatMessage(
              "Identification.UploadDocuments.step1.validDocuments.DrivingLicense"
            ),
            formatMessage(
              "Identification.UploadDocuments.step1.validDocuments.Passport"
            ),
          ]}
        </UnorderedList>
      </Stack>

      <Stack shrink fluid>
        <ExampleDocument
          image={drivingLicense}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.step1.validDocuments.DrivingLicense"
          )}
          description={option.none}
        />
        <ExampleDocument
          image={passport}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.step1.validDocuments.Passport"
          )}
          description={option.none}
        />
      </Stack>
    </Stack>
  );

  const livenessCheckExample = (
    <Stack column grow shrink units={5}>
      <Heading size="small" weight="medium">
        {formatMessage("Identification.UploadDocuments.mobile.livenessCheck")}
      </Heading>

      <Stack column units={3}>
        <HowToDescription
          image={livenessCheckImageSet.normal}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.ok"
          )}
          description={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.okDescription"
          )}
        />
        <HowToDescription
          image={livenessCheckImageSet.dark}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.tooDark"
          )}
          description={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.tooDarkDescription"
          )}
          isBad
        />
        <HowToDescription
          image={livenessCheckImageSet.partial}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.notCentered"
          )}
          description={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.notCenteredDescription"
          )}
          isBad
        />
        <HowToDescription
          image={livenessCheckImageSet.sunglasses}
          imageAlt={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.notRecognisable"
          )}
          description={formatMessage(
            "Identification.UploadDocuments.mobile.livenessCheck.notRecognisableDescription"
          )}
          isBad
        />
      </Stack>
    </Stack>
  );

  return (
    <StartProcess
      onContinue={props.onContinue}
      title={formatMessage(
        props.isProofOfIncome
          ? "Identification.UploadDocuments.mobile.proofOfIncome.welcomeTitle"
          : "Identification.UploadDocuments.mobile.welcomeTitle"
      )}
      subtitle={
        props.documentIdentificationFlow === "PrimaryAndSecondary" ||
        props.documentIdentificationFlow === "PrimaryAndLivenessCheck"
          ? option.none
          : option.some(
              formatMessage(
                props.isProofOfIncome
                  ? "Identification.UploadDocuments.mobile.proofOfIncome.howToTitle"
                  : "Identification.UploadDocuments.mobile.howToTitle"
              )
            )
      }
    >
      {props.documentIdentificationFlow === "PrimaryAndSecondary" ? (
        <Stack column grow shrink units={5}>
          {primaryDocumentsExample}
          {secondaryDocumentsExample}
          <Panel
            status="active"
            title={formatMessage(
              "Identification.UploadDocuments.mobile.howToTitle"
            )}
            headerIcon={option.none}
            isOpen={howToIsOpen}
            onSelect={option.some(() => setHowToIsOpen(!howToIsOpen))}
            content={constant(howToSection)}
          />
        </Stack>
      ) : props.documentIdentificationFlow === "PrimaryAndLivenessCheck" ? (
        <Stack column grow shrink units={5}>
          {primaryDocumentsExample}
          <Panel
            status="active"
            title={formatMessage(
              "Identification.UploadDocuments.mobile.howToTitle"
            )}
            headerIcon={option.none}
            isOpen={howToIsOpen}
            onSelect={option.some(() => setHowToIsOpen(!howToIsOpen))}
            content={constant(howToSection)}
          />
          {livenessCheckExample}
        </Stack>
      ) : (
        howToSection
      )}
      {isMobileLayout && (
        <>
          <Space units={5} />
          <HelpLineSupportDocumentId />
        </>
      )}
    </StartProcess>
  );
}
