import { useFormatMessage } from "../../../intl";
import { useAppContext } from "../../../useAppContext";
import { useIsRemoteChannel } from "../../../useChannel";
import { Body, Box, LocalizedString, SelfieIcon, Space } from "design-system";
import * as classes from "../../UploadId.treat";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { DocumentObject } from "../../domain";
import {
  DocumentIdentificationFlow,
  foldTenant,
  UploadDocumentFlowType,
} from "../../../globalDomain";
import IdCardImage_SK from "../../idcard_sk.png";
import Passport_CZ from "../../passport_cz.png";
import IdCardImage_CZ from "../../idcard_cz.png";
import Passport_SK from "../../passport_sk.png";
import DrivingLicense_SK from "../../drivinglicense_sk.png";
import DrivingLicense_CZ from "../../drivinglicense_cz.png";
import { formatDocumentType } from "../../utils";
import { Option } from "fp-ts/Option";

export type Props = {
  hasPrimaryDocuments: boolean;
  hasSecondaryDocuments: boolean;
  productType: Option<UploadDocumentFlowType>;
  documentIdentificationFlow: DocumentIdentificationFlow;
};

export function MobileRequiredDocuments(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant, channel },
  } = useAppContext();
  const isRemote = useIsRemoteChannel();

  const DocumentCard = ({
    src,
    message,
  }: {
    src: string;
    message: LocalizedString;
  }) => {
    return (
      <Box hAlignContent="left">
        <Box className={classes.imageWrapper} hAlignContent="center">
          <img src={src} alt={message} height={48} />
        </Box>
        <Space units={2} />
        <Box style={{ alignItems: "center" }} shrink>
          <Body size="small" weight="bold">
            {message}
          </Body>
        </Box>
      </Box>
    );
  };

  const DocumentCardSelfie = () => {
    return (
      <Box hAlignContent="left">
        <Box
          className={classes.imageWrapper}
          style={{ height: 48, width: 76 }}
          hAlignContent="center"
          vAlignContent="center"
        >
          <SelfieIcon size={"x-large"} />
        </Box>
        <Space units={2} />
        <Box style={{ alignItems: "center" }} shrink>
          <Body size="small" weight="bold">
            {formatMessage(
              "Identification.UploadDocuments.step1.validDocuments.Selfie"
            )}
          </Body>
        </Box>
      </Box>
    );
  };

  const isPersonalProfile = pipe(
    props.productType,
    option.exists(value => value === "PersonalProfile")
  );

  const primaryDocumentsImages: DocumentObject[] = foldTenant(
    tenant,
    () =>
      isRemote && !isPersonalProfile
        ? [{ src: IdCardImage_SK, type: "IDCard" }]
        : [
            { src: IdCardImage_SK, type: "IDCard" },
            { src: Passport_CZ, type: "Passport" },
          ],
    () =>
      (isRemote || channel === "TLS_Remote") && !isPersonalProfile
        ? [{ src: IdCardImage_CZ, type: "IDCard" }]
        : [
            { src: IdCardImage_CZ, type: "IDCard" },
            { src: Passport_SK, type: "Passport" },
          ]
  );

  const secondaryDocumentsImages: DocumentObject[] = foldTenant(
    tenant,
    () =>
      !isPersonalProfile
        ? []
        : [
            { src: DrivingLicense_SK, type: "DrivingLicenseSK" },
            { src: Passport_SK, type: "Passport" },
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
          ],
    () =>
      isPersonalProfile
        ? [
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
            { src: Passport_CZ, type: "Passport" },
            { src: DrivingLicense_SK, type: "DrivingLicenseSK" },
          ]
        : isRemote || channel === "TLS_Remote"
        ? [
            { src: DrivingLicense_CZ, type: "DrivingLicenseCZ" },
            { src: Passport_CZ, type: "Passport" },
          ]
        : []
  );

  const primary = (
    <DocumentCard
      src={primaryDocumentsImages[0].src}
      message={
        primaryDocumentsImages.length === 1
          ? formatMessage(formatDocumentType(primaryDocumentsImages[0].type))
          : formatMessage(
              "Identification.UploadDocuments.whatToUpload.validDocuments",
              {
                documentType1: formatMessage(
                  formatDocumentType(primaryDocumentsImages[0].type)
                ),
                documentType2: formatMessage(
                  formatDocumentType(primaryDocumentsImages[1].type)
                ),
              }
            )
      }
    />
  );
  const showSecondaryImage =
    props.hasSecondaryDocuments ||
    props.documentIdentificationFlow === "PrimaryAndLivenessCheck";
  const secondary = () =>
    props.documentIdentificationFlow === "PrimaryAndLivenessCheck" ? (
      <DocumentCardSelfie />
    ) : (
      <DocumentCard
        src={secondaryDocumentsImages[0].src}
        message={
          secondaryDocumentsImages.length === 1
            ? formatMessage(
                formatDocumentType(secondaryDocumentsImages[0].type)
              )
            : formatMessage(
                "Identification.UploadDocuments.whatToUpload.validDocuments",
                {
                  documentType1: formatMessage(
                    formatDocumentType(secondaryDocumentsImages[0].type)
                  ),
                  documentType2: formatMessage(
                    formatDocumentType(secondaryDocumentsImages[1].type)
                  ),
                }
              )
        }
      />
    );

  return (
    <>
      {props.hasPrimaryDocuments && primary}
      {showSecondaryImage && (
        <>
          <Space units={6} />
          {secondary()}
        </>
      )}
    </>
  );
}
