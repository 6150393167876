import { ComponentProps, useState } from "react";
import {
  Body,
  Space,
  Loader,
  Box,
  Heading,
  MobileUploadIDIcon,
  Dialog,
  Action,
  Stack,
  unsafeLocalizedString,
  Button,
} from "design-system";
import { useQuery } from "../useAPI";
import * as uploadApi from "./api";
import { useFormatMessage } from "../intl";
import { Option } from "fp-ts/Option";
import { option, array, nonEmptyArray } from "fp-ts";
import { pipe, constNull, constant, flow } from "fp-ts/function";
import * as remoteData from "../RemoteData";
import { MobileDeviceSelection } from "./MobileDeviceSelection";
import { LinkPurpose, MobileRecipientType } from "./domain";
import { Task } from "fp-ts/Task";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { MobileFlowType, MobileRecipientOptionType } from "./state";
import { CoApplicantInput } from "../globalDomain";
import { foldMobileFlowType } from "./utils";
import { useAppContext } from "../useAppContext";
import { usePortalStatusContext } from "../PortalStatusContext";

type Props = {
  type: MobileFlowType;
  onSelect: (recipient: MobileRecipientOptionType) => unknown;
  onDismiss: () => unknown;
  onUploadAgain: Option<Task<unknown>>;
  onMockScannerUpload: Option<() => unknown>;
} & CoApplicantInput;

function foldType<T>(
  type: Props["type"],
  match: {
    [k in Props["type"]]: () => T;
  }
) {
  return match[type]();
}

export function getRecipientOptions(
  recipients: uploadApi.MobileRecipientsOutput
): Option<NonEmptyArray<MobileRecipientOptionType>> {
  return pipe(
    [
      {
        key: "Client" as MobileRecipientType,
        ...recipients.Client,
      },
      { key: "Banker" as MobileRecipientType, ...recipients.Banker },
    ],
    array.filterMap(r =>
      pipe(
        r.phoneNumber,
        option.map(phoneNumber => ({ ...r, phoneNumber }))
      )
    ),
    nonEmptyArray.fromArray,
    option.filter<NonEmptyArray<MobileRecipientOptionType>>(
      array.some(r => !r.disabled)
    )
  );
}

export function ChooseMobileDeviceDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const [recipients] = useQuery(uploadApi.mobileRecipients, {
    coApplicant: props.coApplicant,
    linkPurpose: foldMobileFlowType<LinkPurpose>(props.type, {
      ProofOfIncome: constant("proofOfIncome"),
      DocumentUpload: constant("mobileIdUpload"),
      SelfieFraudCheck: constant("selfieFraudCheck"),
      HologramFraudCheck: constant("hologramFraudCheck"),
      HologramAndSelfieFraudCheck: constant("hologramAndSelfieFraudCheck"),
    }),
  });
  const { portalBlocked } = usePortalStatusContext();

  const {
    config: { mockScanner },
  } = useAppContext();

  const [recipient, setRecipient] = useState<Option<MobileRecipientOptionType>>(
    option.none
  );

  const title = foldType(props.type, {
    DocumentUpload: constant(
      formatMessage("Identification.UploadDocuments.chooseMobileRecipientTitle")
    ),
    ProofOfIncome: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseProofOfIncomeMobileRecipientTitle"
      )
    ),
    SelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseSelfieMobileRecipientTitle"
      )
    ),
    HologramFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseHologramMobileRecipientTitle"
      )
    ),
    HologramAndSelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseSelfieAndHologramMobileRecipientTitle"
      )
    ),
  });

  const description = foldType(props.type, {
    DocumentUpload: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseMobileRecipientDescription"
      )
    ),
    ProofOfIncome: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseProofOfIncomeMobileRecipientDescription"
      )
    ),
    SelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
    HologramFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
    HologramAndSelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
  });

  const uploadAgainAction: Option<Action> = pipe(
    props.onUploadAgain,
    option.map(onUploadAgain => ({
      variant: "secondary",
      label: formatMessage("Identification.UploadDocuments.uploadAgain"),
      action: onUploadAgain,
    }))
  );

  const selectAction: Action = {
    variant: "primary",
    label: formatMessage("Identification.UploadDocuments.sendLink"),
    action: () => pipe(recipient, option.map(props.onSelect)),
  };

  const actions: ComponentProps<typeof Dialog>["actions"] = pipe(
    uploadAgainAction,
    option.fold<Action, ComponentProps<typeof Dialog>["actions"]>(
      () => [selectAction],
      uploadAgainAction => [uploadAgainAction, selectAction]
    )
  );
  return (
    <Dialog
      variant="center"
      size="medium"
      icon={MobileUploadIDIcon}
      title={title}
      onDismiss={option.some(props.onDismiss)}
      actions={!portalBlocked ? actions : []}
    >
      <Stack column grow units={10}>
        {pipe(
          recipients,
          remoteData.fold(
            () => (
              <Box hAlignContent="center" vAlignContent="center">
                <Loader />
              </Box>
            ),
            constNull,
            flow(
              getRecipientOptions,
              option.fold(constNull, options => (
                <Box column hAlignContent="center">
                  <Body size="medium" weight="regular">
                    {description}
                  </Body>
                  <Space units={5} />
                  <Heading size="small" weight="medium">
                    {formatMessage(
                      "Identification.UploadDocuments.chooseMobileRecipientSubtitle"
                    )}
                  </Heading>
                  <Space units={5} />
                  <MobileDeviceSelection
                    recipients={options}
                    value={recipient}
                    onChange={value => setRecipient(option.some(value))}
                    disabled={portalBlocked}
                  />
                </Box>
              ))
            )
          )
        )}
        {mockScanner &&
          pipe(
            props.onMockScannerUpload,
            option.fold(constNull, onMockScannerUpload => (
              <Button
                variant="secondary"
                size="default"
                label={unsafeLocalizedString("Mock scanner")}
                action={onMockScannerUpload}
                disabled={portalBlocked}
              />
            ))
          )}
      </Stack>
    </Dialog>
  );
}
