import { lazy, Suspense, useRef } from "react";
import { either, option, taskEither } from "fp-ts";
import {
  constant,
  constNull,
  constUndefined,
  constVoid,
  flow,
  pipe,
} from "fp-ts/function";
import { useAppContext } from "../useAppContext";
import { IframePortal } from "../Common/IframePortal/IframePortal";
import {
  DocumentToUploadDetail,
  DocumentType,
  ScannedDocument,
} from "./domain";
import { TaskEither } from "fp-ts/TaskEither";
import { useAuthenticationContext } from "design-system";
import { DocumentPurpose } from "../globalDomain";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

type Props = {
  _mockScanner: boolean;
  onDocumentAcquired: (
    scannedDocument: ScannedDocument
  ) => TaskEither<unknown, unknown>;
  onFailure: () => unknown;
  documentPurpose: Option<DocumentPurpose>;
  documentDetails: Option<DocumentToUploadDetail>;
};

const MockedUpload = lazy(() => import("./mock/UploadImageMock"));

function renderMockedUpload(props: {
  origin: string;
  messageHandler: (message: unknown) => TaskEither<unknown, unknown>;
  documentPurpose: Option<DocumentPurpose>;
  countryCode?: NonEmptyString;
  idType?: DocumentType;
}) {
  return (
    <Suspense fallback={constNull}>
      <MockedUpload {...props} />
    </Suspense>
  );
}

export function ScanDocument(props: Props) {
  const {
    config: { scannerAppUrl },
    apiParameters: { tenant },
    locale,
  } = useAppContext();
  const { authInfo } = useAuthenticationContext();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  function signalUploadResult(success: boolean): TaskEither<unknown, unknown> {
    return taskEither.fromIO(() =>
      pipe(
        iframeRef.current,
        option.fromNullable,
        option.chainNullableK(iframe => iframe.contentWindow),
        option.map(w => () =>
          w.postMessage(
            {
              type: "UploadComplete",
              success,
            },
            "*"
          )
        ),
        option.getOrElseW(constant(constVoid))
      )()
    );
  }

  const handleMessageReceived = (
    message: unknown
  ): TaskEither<unknown, unknown> => {
    return pipe(
      message,
      ScannedDocument.decode,
      either.fold(
        flow(
          () => signalUploadResult(false),
          taskEither.chain(() => taskEither.fromIO(props.onFailure))
        ),
        flow(
          props.onDocumentAcquired,
          taskEither.chainFirst(() => signalUploadResult(true)),
          taskEither.orElse(err =>
            pipe(
              signalUploadResult(false),
              taskEither.chain(() => taskEither.left(err))
            )
          )
        )
      )
    );
  };

  return props._mockScanner ? (
    renderMockedUpload({
      origin: scannerAppUrl,
      messageHandler: handleMessageReceived,
      documentPurpose: props.documentPurpose,
      countryCode: pipe(
        props.documentDetails,
        option.fold(constUndefined, doc => doc.country.countryCode)
      ),
      idType: pipe(
        props.documentDetails,
        option.map(doc => doc.type),
        option.flatten,
        option.getOrElseW(constUndefined)
      ),
    })
  ) : (
    <IframePortal
      ref={iframeRef}
      src={scannerAppUrl
        .replace(/\{tenant\}/g, tenant)
        .replace(/\{locale\}/g, locale)
        .replace(
          /\{flowId\}/g,
          pipe(
            authInfo,
            option.map(i => i.flowId),
            option.getOrElse(() => "missing")
          )
        )}
      onMessageReceived={message => handleMessageReceived(message)()}
      width={760}
      height={350}
    />
  );
}
