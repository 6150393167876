import {
  Space,
  Stack,
  DocumentsIcon,
  Box,
  Heading,
  Button,
} from "design-system";
import { Option } from "fp-ts/Option";
import { option } from "fp-ts";
import { pipe, constNull } from "fp-ts/function";
import * as classes from "./ReviewAndUpload.treat";
import { DocumentSide } from "./domain";
import { StickyBottomContainer } from "../StickyBottomContainer";
import { useFormatMessage } from "../../../intl";

type Props = {
  onRetakePhoto: (documentSide: DocumentSide) => void;
  onOpenImage: (documentSide: DocumentSide) => void;
  onUpload: () => void;
  onUploadRejected: () => void;
  firstImageSource: Option<string>;
  secondImageSource: Option<string>;
};

export const ReviewAndUpload = (props: Props) => {
  const formatMessage = useFormatMessage();

  return (
    <Box column grow shrink className={classes.container}>
      <Heading size="medium" weight="medium">
        {formatMessage(
          "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.title"
        )}
      </Heading>
      <Box>
        {formatMessage(
          "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.subtitle"
        )}
      </Box>
      <Stack divider column>
        {pipe(
          props.firstImageSource,
          option.fold(constNull, () => (
            <Box>
              <Button
                variant="text"
                size="default"
                label={formatMessage(
                  "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.firstPage"
                )}
                action={() => props.onOpenImage("FirstSide")}
                icon={DocumentsIcon}
              />
              <Space fluid />
              <Button
                variant="text"
                size="default"
                label={formatMessage(
                  "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.retake"
                )}
                action={() => props.onRetakePhoto("FirstSide")}
              />
            </Box>
          ))
        )}
        {pipe(
          props.secondImageSource,
          option.fold(constNull, () => (
            <Box>
              <Button
                variant="text"
                size="default"
                label={formatMessage(
                  "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.secondPage"
                )}
                action={() => props.onOpenImage("SecondSide")}
                icon={DocumentsIcon}
              />
              <Space fluid />
              <Button
                variant="text"
                size="default"
                label={formatMessage(
                  "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.retake"
                )}
                action={() => props.onRetakePhoto("SecondSide")}
              />
            </Box>
          ))
        )}
      </Stack>
      <StickyBottomContainer>
        <Box column>
          <Button
            variant="text"
            label={formatMessage(
              "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.button.exit"
            )}
            size="default"
            action={props.onUploadRejected}
          />
          <Button
            variant="primary"
            label={formatMessage(
              "Identification.UploadDocuments.proofOfIncome.reviewAndUpload.button.upload"
            )}
            size="default"
            action={props.onUpload}
          />
        </Box>
      </StickyBottomContainer>
    </Box>
  );
};
