import { RadioGroupField, unsafeLocalizedString } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../intl";
import { constant, pipe } from "fp-ts/function";

type Props = {
  type: "upload" | "digital";
  setType: (type: "upload" | "digital") => unknown;
};

export function DocumentDigitalIdSwitch(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <RadioGroupField
      variant="horizontal"
      renderOptionChildren={constant(option.none)}
      optionKey={v => v.toString()}
      isOptionDisabled={() => false}
      renderOption={v =>
        v === "upload"
          ? formatMessage("Identification.UploadDocuments.title")
          : formatMessage("Identification.DigitalId.title")
      }
      options={["upload", "digital"]}
      label={unsafeLocalizedString("")}
      issues={option.none}
      name="digitalIdSwitch"
      onBlur={() => {}}
      onChange={v =>
        pipe(
          v,
          option.fold(
            () => {},
            t => props.setType(t as "upload" | "digital")
          )
        )
      }
      value={option.some(props.type)}
    />
  );
}
