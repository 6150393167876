import { Box } from "design-system";
import * as classes from "./StickyBottomContainer.treat";
type Props = {
  children: JSX.Element;
};

export const StickyBottomContainer = (props: Props) => {
  return (
    <Box column grow className={classes.bottomActionContainer}>
      {props.children}
    </Box>
  );
};
