import { useEffect, useRef, useState } from "react";
import {
  Box,
  CheckboxField,
  Divider,
  LoadingButton,
  Space,
  Stack,
} from "design-system";
import { option, taskEither } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../intl";
import { constant, pipe } from "fp-ts/function";
import { useAppContext } from "../useAppContext";
import { CoApplicantInput } from "../globalDomain";
import { useCommand } from "../useAPI";
import * as uploadApi from "./api";
import * as digitalIdApi from "../DigitalId/api";
import { BiometricConsentStatus } from "../IdUpload/types";
import { BiometricConsent } from "./BiometricConsent";
import { selectedMainApplicant } from "../MortgageDashboard/mortgageDashboardUtils";
import { CheckClientDataResult } from "./useCheckClientDataResult";
import { DocumentDigitalIdSwitch } from "./DocumentDigitalIdSwitch";
import { useBranchExperienceContext } from "../BranchExperience/BranchExperienceContext";
import { DigitalId } from "../DigitalId/DigitalId";
import { ClientDataOutput } from "../IdUpload/api";
import { TaskEither } from "fp-ts/TaskEither";

type Props = {
  onContinue: (data: CheckClientDataResult) => unknown;
  biometricConsent: BiometricConsentStatus;
  setBiometricConsent: (consent: BiometricConsentStatus) => void;
  digitalIdSwitch: {
    type: "upload" | "digital";
    setType: (type: "upload" | "digital") => unknown;
  };
} & CoApplicantInput;

export function DocumentDigitalId({
  digitalIdSwitch: { type, setType },
  ...props
}: Props) {
  const { openChildWindow } = useBranchExperienceContext();
  const {
    config: { biometricConsent: biometricConsentEnabled },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const [error, setError] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(false);
  const [agreementError, setAgreementError] = useState<boolean>(false);
  const sendBiometricConsentCommand = useCommand(
    uploadApi.sendBiometricConsent
  );
  const extractData = useCommand(digitalIdApi.digitalIdExtractData);
  const [clientIdentified, setClientIdentified] = useState<boolean>(false);

  useEffect(() => {
    openChildWindow();
  }, []);

  const scrollToBiometricError = () => {
    if (!biometricSectionRef.current) {
      return;
    }
    biometricSectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const extract = pipe(
    extractData(),
    taskEither.map(({ primary, canUploadAgain }: ClientDataOutput) =>
      pipe(
        primary,
        option.fold(
          () => {},
          data =>
            props.onContinue({
              ...data,
              canUploadAgain,
              canEdit: false,
              showWarning: false,
              fraudCheck: option.none,
              additionalDocumentType: option.none,
              additionalDocumentDetails: option.none,
            })
        )
      )
    )
  );

  const onContinue = (): TaskEither<unknown, unknown> => {
    if (!agreement) {
      return taskEither.leftIO(() => {
        setAgreementError(true);
      });
    }
    const applicantIndex = pipe(
      props.coApplicant,
      option.fold(constant(selectedMainApplicant.index), c => c.index)
    );

    return pipe(
      props.biometricConsent.consent,
      option.fold(
        () =>
          taskEither.leftIO(() => {
            setError(true);
            scrollToBiometricError();
          }),
        consent =>
          pipe(
            sendBiometricConsentCommand({ consent, applicantIndex }),
            taskEither.chain(() => extract)
          )
      )
    );
  };

  const updateBiometricConsent = (consent: Option<boolean>) => {
    setError(false);
    props.setBiometricConsent({
      consent,
      previousConsent: option.none,
    });
  };

  const biometricSectionRef = useRef<HTMLElement>(null);

  const biometricSection = (
    <>
      <BiometricConsent
        ref={biometricSectionRef}
        haveErrors={error}
        biometricConsent={props.biometricConsent.consent}
        onBiometricConsent={updateBiometricConsent}
        isRadioDisabled={false}
        mustGiveConsent={false}
      />
      <Space units={6} />
      <Divider />
    </>
  );

  return (
    <Stack grow column units={6}>
      {biometricConsentEnabled && biometricSection}
      <DocumentDigitalIdSwitch type={type} setType={setType} />
      <DigitalId
        onClientIdentified={() => setClientIdentified(true)}
        applicantIndex={pipe(
          props.coApplicant,
          option.fold(
            () => undefined,
            coApplicant => coApplicant.index + ""
          )
        )}
      />
      <CheckboxField
        label={formatMessage("Identification.client.agreement")}
        disabled={false}
        issues={
          agreementError
            ? option.some([
                {
                  type: "error",
                  value: formatMessage(
                    "Identification.client.missing.confirmation"
                  ),
                },
              ])
            : option.none
        }
        name="agreement"
        value={agreement}
        onChange={val => {
          setAgreement(val);
          setAgreementError(false);
        }}
      />
      <Box hAlignContent="right">
        <LoadingButton
          disabled={!clientIdentified}
          labels={{
            normal: formatMessage("Continue"),
            loading: formatMessage("Continue"),
            success: formatMessage("Continue"),
            error: formatMessage("Continue"),
          }}
          variant="primary"
          size="default"
          action={onContinue()}
          data-test-id="upload-documents-next"
        />
      </Box>
    </Stack>
  );
}
