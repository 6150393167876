import { Box, Button, Heading, Space } from "design-system";
import * as classes from "./ConfirmPhoto.treat";
import { StickyBottomContainer } from "../StickyBottomContainer";
import { useFormatMessage } from "../../../intl";

type Props = {
  imgSource: string;
  onContinue: () => void;
  onRetake: () => void;
};

export const ConfirmPhoto = (props: Props) => {
  const formatMessage = useFormatMessage();
  return (
    <Box column grow shrink className={classes.container}>
      <Heading size="medium" weight="medium">
        {formatMessage(
          "Identification.UploadDocuments.proofOfIncome.confirmPhoto.title"
        )}
      </Heading>
      <Box>
        {formatMessage(
          "Identification.UploadDocuments.proofOfIncome.confirmPhoto.subtitle"
        )}
      </Box>
      <Space units={4} />
      <Box
        hAlignContent="center"
        vAlignContent="center"
        className={classes.imgWrapper}
      >
        <img
          width={window.innerWidth / 2}
          height={window.innerHeight / 2}
          src={props.imgSource}
          alt="uploaded"
        />
      </Box>
      <StickyBottomContainer>
        <Box column>
          <Button
            variant="text"
            label={formatMessage(
              "Identification.UploadDocuments.proofOfIncome.confirmPhoto.button.reject"
            )}
            size="default"
            action={props.onRetake}
          />
          <Button
            variant="primary"
            label={formatMessage(
              "Identification.UploadDocuments.proofOfIncome.confirmPhoto.button.confirm"
            )}
            size="default"
            action={props.onContinue}
          />
        </Box>
      </StickyBottomContainer>
    </Box>
  );
};
