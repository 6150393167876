import { useState } from "react";
import { option } from "fp-ts";
import { ScannedDocument } from "./domain";
import { useFormatMessage } from "../intl";
import { ScanDocument } from "./ScanDocument";
import { TaskEither } from "fp-ts/TaskEither";
import { Body, Box, Dialog, ErrorBanner, Space } from "design-system";

type Props = {
  _mockScanner: boolean;
  onDocumentAcquired: (
    scannedDocument: ScannedDocument
  ) => TaskEither<unknown, unknown>;
  onDismiss: () => unknown;
};

export function GenericDocumentScanDialog(props: Props) {
  const formatMessage = useFormatMessage();

  const [hasError, setHasError] = useState(false);

  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage(
        "Identification.UploadDocuments.scanner.customScanTitle"
      )}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <Box shrink>
        <Body size="medium" weight="regular">
          {formatMessage(
            "Identification.UploadDocuments.scanner.customScanDescription"
          )}
        </Body>
      </Box>
      <Space units={10} />
      <Box hAlignContent="center">
        <ScanDocument
          _mockScanner={props._mockScanner}
          onDocumentAcquired={props.onDocumentAcquired}
          onFailure={() => setHasError(false)}
          documentPurpose={option.none}
          documentDetails={option.none}
        />
      </Box>
      {hasError && (
        <ErrorBanner>
          {formatMessage("Identification.UploadDocuments.genericError")}
        </ErrorBanner>
      )}
    </Dialog>
  );
}
