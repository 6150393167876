import { useEffect, useMemo } from "react";
import { MobileRecipientType } from "./domain";
import { Option } from "fp-ts/Option";
import { constVoid, pipe } from "fp-ts/function";
import { array, option } from "fp-ts";
import {
  LocalizedString,
  unsafeLocalizedString,
  RadioGroup,
} from "design-system";
import { useFormatMessage } from "../intl";

import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { MobileRecipientOptionType } from "./state";

type Props = {
  recipients: NonEmptyArray<MobileRecipientOptionType>;
  value: Option<MobileRecipientOptionType>;
  onChange: (recipient: MobileRecipientOptionType) => unknown;
  disabled?: boolean;
};

export function MobileDeviceSelection(props: Props) {
  const formatMessage = useFormatMessage();

  const defaultRecipient: Option<MobileRecipientOptionType> = useMemo(
    () =>
      pipe(
        props.recipients,
        array.filter(r => !r.disabled),
        array.head
      ),
    [props.recipients]
  );

  useEffect(() => {
    pipe(
      props.value,
      option.fold(
        () => pipe(defaultRecipient, option.fold(constVoid, props.onChange)),
        constVoid
      )
    );
  }, [defaultRecipient]);

  const renderRecipient = (recipient: MobileRecipientType): LocalizedString => {
    switch (recipient) {
      case "Banker":
        return formatMessage(
          "Identification.UploadDocuments.bankerPhoneNumber"
        );
      case "Client":
        return formatMessage(
          "Identification.UploadDocuments.clientPhoneNumber"
        );
    }
  };

  return (
    <RadioGroup
      name="recipient"
      variant="horizontal"
      value={props.value}
      onChange={option.fold(constVoid, props.onChange)}
      options={props.recipients}
      optionKey={recipient => recipient.key}
      isOptionDisabled={recipient => recipient.disabled}
      renderOption={option => renderRecipient(option.key)}
      renderOptionChildren={recipient =>
        option.some(unsafeLocalizedString(recipient.phoneNumber))
      }
      issuesType={option.none}
      disabled={props.disabled}
    />
  );
}
