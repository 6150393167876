import { Country, DocumentTypeUpload } from "../domain";
import {
  DropdownField,
  DropdownFieldProps,
} from "../../Common/DropdownField/DropdownField";
import { useFormatMessage } from "../../intl";
import { array, option } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import { useQuery } from "../../useAPI";
import * as api from "../api";
import * as remoteData from "../../RemoteData";
import { ErrorBanner, Loader } from "design-system";
import { formatDocumentType } from "../utils";
import { dropdownOptionToValue } from "../../Common/selectDropdownOption";

export type Props = Omit<
  DropdownFieldProps<"IDCard" | "Passport">,
  "options" | "onChange" | "searchable"
> & {
  country: Country;
  onSelectionChange: (type: "IDCard" | "Passport") => unknown;
  disabled: boolean;
};

export function DocumentTypes(props: Props) {
  const formatMessage = useFormatMessage();
  const [acceptableDocTypes] = useQuery(api.documentTypes, {
    iso3country: props.country.countryCode,
  });

  return pipe(
    acceptableDocTypes,
    remoteData.fold(
      () => <Loader />,
      () => <ErrorBanner children={formatMessage("GenericError")} />,
      types => (
        <DropdownField
          {...props}
          options={pipe(
            types,
            array.map(docType => ({
              value: docType as "IDCard" | "Passport",
              label: formatMessage(
                formatDocumentType(docType as "IDCard" | "Passport")
              ),
            }))
          )}
          onChange={newVal =>
            pipe(
              dropdownOptionToValue(newVal),
              option.fold(constVoid, val =>
                props.onSelectionChange(val as "IDCard" | "Passport")
              )
            )
          }
          searchable={false}
          disabled={props.disabled}
          value={
            option.isSome(props.value)
              ? option.some({
                  value: props.value.value.value,
                  label: formatMessage(
                    formatDocumentType(
                      props.value.value.value as DocumentTypeUpload
                    )
                  ),
                })
              : option.none
          }
        />
      )
    )
  );
}
