import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import * as api from "../api";
import { constant, constFalse, constVoid, pipe } from "fp-ts/function";
import { array, boolean, nonEmptyArray, option } from "fp-ts";
import * as remoteData from "../../RemoteData";
import {
  Box,
  DropdownOption,
  Issue,
  Loader,
  RadioGroup,
  unsafeLocalizedString,
} from "design-system";
import { useState } from "react";
import { Country } from "../domain";
import { useUpdateEffect } from "react-use";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { RestoreApplicationData } from "../../UKontoSecondPart/api";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import { dropdownOptionToValue } from "../../Common/selectDropdownOption";
import { IO } from "fp-ts/IO";

export type Props = {
  onSelectionChange: (newSelection: Option<Country>) => unknown;
  issues: option.Option<nonEmptyArray.NonEmptyArray<Issue>>;
  onNoCountries?: () => unknown;
  localCountryCode: NonEmptyString;
  restoredData?: RestoreApplicationData;
  disabled: boolean;
  handleReset: IO<void>;
};

//supported foreign countries for onboarding
export function Countries(props: Props) {
  const formatMessage = useFormatMessage();

  const [getCountries] = useQuery(api.allCountries);
  const [hasCountries, setHasCountries] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState<Option<Country>>(
    props.restoredData &&
      option.isSome(props.restoredData.countryDocSelection) &&
      option.isSome(props.restoredData.countryDocSelection.value.country)
      ? option.some({
          label:
            props.restoredData.countryDocSelection.value.country.value.label,
          countryCode:
            props.restoredData.countryDocSelection.value.country.value
              .countryCode,
          inEuropeanUnion:
            props.restoredData.countryDocSelection.value.country.value
              .inEuropeanUnion,
        })
      : option.none
  );

  const [localCountrySelected, setLocalCountrySelected] = useState<
    Option<boolean>
  >(
    props.restoredData &&
      option.isSome(props.restoredData.countryDocSelection) &&
      option.isSome(props.restoredData.countryDocSelection.value.country)
      ? option.of(
          props.localCountryCode ===
            props.restoredData.countryDocSelection.value.country.value
              .countryCode
        )
      : option.of(props.localCountryCode !== undefined)
  );

  const handleCountrySelection = (newSelection: Option<Country>) => {
    setSelectedCountry(newSelection);
    props.onSelectionChange(newSelection);
  };

  useUpdateEffect(() => {
    if (!hasCountries && props.onNoCountries) props.onNoCountries();
  }, [hasCountries]);

  pipe(
    getCountries,
    remoteData.fold(constVoid, constVoid, allCountries => {
      if (allCountries.length === 0 && hasCountries) {
        setHasCountries(false);
      }
    })
  );

  const getCountryLabel = (countries: Country[], code: NonEmptyString) =>
    pipe(
      countries,
      array.findFirst(p => p.countryCode === code),
      option.map(c => c.label),
      option.getOrElse(constant(""))
    );

  const showCountries = pipe(
    localCountrySelected,
    option.fold(constFalse, v => !v)
  );

  const setInitialItem = (allCountries: Array<Country>): Array<Country> => {
    let itemToFind = allCountries.find(
      p => p.countryCode === props.localCountryCode
    );
    if (props.localCountryCode === "CZE") {
      itemToFind = allCountries.find(p => p.countryCode === "SVK");
    } else {
      itemToFind = allCountries.find(p => p.countryCode === "CZE");
    }

    if (itemToFind !== undefined) {
      const foundIdx = allCountries.findIndex(index => index === itemToFind);
      allCountries.splice(foundIdx, 1);
      allCountries.unshift(itemToFind);
    }
    return allCountries;
  };

  return pipe(
    getCountries,
    remoteData.fold(
      constant(<Loader />),
      constant(<Loader />),
      allCountries => (
        <Box column grow shrink>
          <RadioGroup
            name={unsafeLocalizedString("Document issuing country")}
            variant="horizontal"
            value={localCountrySelected}
            options={[true, false]}
            onChange={val => {
              props.handleReset();
              setLocalCountrySelected(val);
              setSelectedCountry(option.none);
              if (option.isSome(val) && val.value) {
                pipe(
                  setInitialItem(allCountries),
                  array.findFirst(
                    p => p.countryCode === props.localCountryCode
                  ),
                  option.map(c => handleCountrySelection(option.some(c)))
                );
              } else {
                props.onSelectionChange(option.none);
              }
            }}
            renderOption={boolean.fold(
              () =>
                formatMessage("Identification.personalData.citizenship.OTHER"),
              () =>
                unsafeLocalizedString(
                  getCountryLabel(allCountries, props.localCountryCode)
                )
            )}
            renderOptionChildren={constant(option.none)}
            optionKey={value => value.toString()}
            isOptionDisabled={constFalse}
            issuesType={option.none}
            disabled={props.disabled}
          />
          {showCountries && (
            <DropdownField
              name={"Countries"}
              placeholder={formatMessage(
                "Identification.UploadDocuments.countries.placeholder"
              )}
              value={pipe(
                selectedCountry,
                option.map(
                  country =>
                    ({
                      value: country,
                      label: country.label,
                    } as DropdownOption<Country>)
                )
              )}
              label={formatMessage(
                "Identification.UploadDocuments.countries.label"
              )}
              onChange={val =>
                handleCountrySelection(dropdownOptionToValue(val))
              }
              options={pipe(
                setInitialItem(allCountries),
                array.filter(p => p.countryCode !== props.localCountryCode),
                array.map(
                  country =>
                    ({
                      value: country,
                      label: country.label,
                    } as DropdownOption<Country>)
                )
              )}
              searchable
              clearable={false}
              issues={props.issues}
              onBlur={constVoid}
              disabled={props.disabled}
            />
          )}
        </Box>
      )
    )
  );
}
