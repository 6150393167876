import {
  Box,
  Heading,
  Space,
  Button,
  Body,
  Children,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { Option } from "fp-ts/Option";
import { constUndefined, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { StickyBottomContainer } from "./StickyBottomContainer";

type Props = {
  onContinue: () => unknown;
  title: LocalizedString;
  subtitle: Option<LocalizedString>;
  children: Children;
};

export function StartProcess(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Box column grow shrink>
      <Heading size="medium" weight="medium" align="left">
        {props.title}
      </Heading>
      <Space units={10} />

      {pipe(
        props.subtitle,
        option.fold(constUndefined, subtitle => (
          <Body size="medium" weight="medium" align="left">
            {subtitle}
          </Body>
        ))
      )}

      <Space units={3} />
      {props.children}
      <Space units={16} />
      <StickyBottomContainer>
        <Button
          variant="primary"
          size="default"
          action={props.onContinue}
          label={formatMessage("Identification.UploadDocuments.mobile.next")}
        />
      </StickyBottomContainer>
    </Box>
  );
}
