import { Dialog } from "design-system";

import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/NonEmptyString";
import { useFormatMessage } from "../../../intl";
import {
  CoApplicantInput,
  UploadDocumentFlowType,
} from "../../../globalDomain";
import { ProofOfIncomeUploadSameDeviceFlow } from "./ProofOfIncomeUploadSameDeviceFlow";

type Props = {
  onDismiss: () => void;
  productType: Option<UploadDocumentFlowType>;
  parameters: Option<{
    docTypeId: Option<NonEmptyString>;
    applicationElementID: Option<NonEmptyString>;
  }>;
  onDocumentCancel?: () => void;
} & CoApplicantInput;

export function ProofOfIncomeUploadSameDeviceDialog(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <Dialog
      variant="left"
      size="medium"
      title={formatMessage(
        "Mortgage.Documents.documentUploadDialog.uploadMode.title"
      )}
      onDismiss={option.some(props.onDismiss)}
      actions={[]}
    >
      <ProofOfIncomeUploadSameDeviceFlow {...props} />
    </Dialog>
  );
}
