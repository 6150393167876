import { forwardRef, useState } from "react";
import {
  BiometricIcon,
  Body,
  Box,
  Button,
  Heading,
  InlineMessage,
  RadioGroup,
  Space,
  Stack,
  Banner,
  useIsMobileLayout,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { boolean, option } from "fp-ts";
import { constant, constFalse, identity, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../intl";
import { BiometricConsentInfoDialog } from "./BiometricConsentInfoDialog";

type Props = {
  haveErrors: boolean;
  biometricConsent: Option<boolean>;
  onBiometricConsent: (state: Option<boolean>) => unknown;
  isRadioDisabled: boolean;
  mustGiveConsent: boolean;
};
export const BiometricConsent = forwardRef<HTMLElement, Props>((props, ref) => {
  const formatMessage = useFormatMessage();

  const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false);

  const isMobileLayout = useIsMobileLayout();

  const handleBiometricConsent = (consent: Option<boolean>) => {
    if (option.isNone(consent)) {
      return;
    }
    props.onBiometricConsent(consent);
  };

  const isInfoBannerVisible = pipe(
    props.biometricConsent,
    option.fold(
      () => false,
      consent => !consent
    )
  );

  const shouldBlockUser = pipe(
    props.biometricConsent,
    option.fold(constFalse, consent => !consent && props.mustGiveConsent)
  );

  const heading = (
    <Heading size={isMobileLayout ? "x-small" : "small"} weight="medium">
      {formatMessage("Identification.UploadDocuments.biometricData.title")}
    </Heading>
  );

  const description = (
    <Body
      size="small"
      weight="regular"
      color={isMobileLayout ? palette.dark : undefined}
    >
      {formatMessage(
        "Identification.UploadDocuments.biometricData.description"
      )}
    </Body>
  );

  const radioGroup = (
    <RadioGroup
      name="biometricConsent"
      autoFocus
      variant={isMobileLayout ? "vertical" : "horizontal"}
      options={[true, false]}
      value={props.biometricConsent}
      onChange={handleBiometricConsent}
      isOptionDisabled={() => props.isRadioDisabled}
      isOptionRecommended={identity}
      recommendedLabel={formatMessage(
        "Identification.UploadDocuments.biometricData.optionLabel.recommended"
      )}
      issuesType={props.haveErrors ? option.some("error") : option.none}
      optionKey={value => value.toString()}
      renderOptionChildren={constant(option.none)}
      renderOption={boolean.fold(
        () =>
          formatMessage(
            "Identification.UploadDocuments.biometricData.optionLabel.no"
          ),
        () =>
          formatMessage(
            "Identification.UploadDocuments.biometricData.optionLabel.yes"
          )
      )}
    />
  );

  const banner = isInfoBannerVisible && !shouldBlockUser && (
    <Banner
      type="informative"
      title={option.none}
      actions={option.none}
      onDismiss={option.none}
      content={formatMessage(
        "Identification.UploadDocuments.biometricData.infoBanner"
      )}
    />
  );

  const blockUserError = shouldBlockUser && (
    <>
      <Space units={5} />
      <Banner
        type="error"
        actions={option.none}
        content={formatMessage(
          "Identification.UploadDocuments.step1.biometricConsentRequiredError"
        )}
        title={option.none}
      />
    </>
  );

  const errorMessage = props.haveErrors && (
    <InlineMessage
      type="error"
      size="medium"
      message={formatMessage(
        "Identification.UploadDocuments.biometricData.error"
      )}
    />
  );

  const mobileLayout = (
    <>
      {heading}
      <Space units={2} />
      {description}
      <Space units={4} />
      {radioGroup}
      {banner}
      {errorMessage}
      {blockUserError}
    </>
  );

  const desktopLayout = (
    <>
      {moreInfoModalOpen && (
        <BiometricConsentInfoDialog
          onDismiss={() => setMoreInfoModalOpen(false)}
        />
      )}
      <Stack vAlignContent="center" units={5}>
        {heading}
        <Button
          label={formatMessage("MoreInfo")}
          variant="text"
          size="default"
          action={() => setMoreInfoModalOpen(true)}
        />
      </Stack>
      <Space units={5} />
      <Stack units={5} vAlignContent="top">
        <BiometricIcon size="x-large" />
        <Box column shrink ref={ref}>
          {description}
          {radioGroup}
          {banner}
          {errorMessage}
          {blockUserError}
        </Box>
      </Stack>
    </>
  );

  return isMobileLayout ? mobileLayout : desktopLayout;
});
