import { useState } from "react";
import { option, taskEither, either, array } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { StartIdProcess as StartProcess } from "../StartIdProcess";
import * as uploadApi from "../../api";
import * as loansApi from "../../../StandardLoan/api";
import * as documentApi from "../../../MortgageDashboard/Documents/api";
import {
  Box,
  FeedbackBlock,
  FileContent,
  PositiveInteger,
} from "design-system";
import {
  fromUploadErrorToFeedbackState,
  MobileUploadFeedbackBanner,
  MobileUploadState,
} from "../FeedbackBanner";
import { useFormatMessage } from "../../../intl";
import {
  CoApplicantInput,
  genericError,
  UploadDocumentFlowType,
} from "../../../globalDomain";
import { DocumentSide, foldDocumentSide, foldUploadFlowType } from "./domain";
import { ReviewAndUpload } from "./ReviewAndUpload";
import { SealingProofOfIncome } from "./SealingProofOfIncome";
import { ConfirmPhoto } from "./ConfirmPhoto";
import { TakePhoto } from "./TakePhoto";
import { useCommand } from "../../../useAPI";
import { sequenceS } from "fp-ts/Apply";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { AddDocumentNote } from "../../../Common/Dialogs/DocumentUploadDialog/AddDocumentNote";
import { DocumentNoteUploadError } from "../../../Common/Dialogs/DocumentUploadDialog/domain";

type FlowState =
  | { id: "StartProcess" }
  | { id: "Loading" }
  | { id: "FirstPageUploaded" }
  | { id: "UploadFirstSide" }
  | { id: "UploadSecondSide" }
  | { id: "ConfirmData"; imgSource: string; documentSide: DocumentSide }
  | { id: "AddNote" }
  | { id: "UploadCompleted" }
  | { id: "Sealing" }
  | { id: "UploadRejected" }
  | { id: "UploadFailed" }
  | { id: "ConnectionLost"; error: uploadApi.MobileUploadStatusError }
  | { id: "ReviewAndUpload" };

function foldState<T>(
  state: FlowState,
  match: {
    whenLoading: () => T;
    whenStartProcess: () => T;
    whenFirstPageUploaded: () => T;
    whenUploadFirstSide: () => T;
    whenUploadSecondSide: () => T;
    whenConfirmData: ({
      imgSource,
      documentSide,
    }: {
      imgSource: string;
      documentSide: DocumentSide;
    }) => T;
    whenAddNote: () => T;
    whenUploadCompleted: () => T;
    whenSealing: () => T;
    whenUploadRejected: () => T;
    whenUploadFailed: () => T;
    whenConnectionLost: (error: uploadApi.MobileUploadStatusError) => T;
    whenReviewAndUpload: () => T;
  }
) {
  switch (state.id) {
    case "Loading":
      return match.whenLoading();
    case "StartProcess":
      return match.whenStartProcess();
    case "FirstPageUploaded":
      return match.whenFirstPageUploaded();
    case "UploadFirstSide":
      return match.whenUploadFirstSide();
    case "UploadSecondSide":
      return match.whenUploadSecondSide();
    case "ConfirmData":
      return match.whenConfirmData({
        imgSource: state.imgSource,
        documentSide: state.documentSide,
      });
    case "AddNote":
      return match.whenAddNote();
    case "UploadCompleted":
      return match.whenUploadCompleted();
    case "Sealing":
      return match.whenSealing();
    case "UploadRejected":
      return match.whenUploadRejected();
    case "UploadFailed":
      return match.whenUploadFailed();
    case "ConnectionLost":
      return match.whenConnectionLost(state.error);
    case "ReviewAndUpload":
      return match.whenReviewAndUpload();
  }
}

type Props = {
  productType: Option<UploadDocumentFlowType>;
  parameters: Option<{
    docTypeId: Option<NonEmptyString>;
    applicationElementID: Option<NonEmptyString>;
  }>;
  maxLengthNote?: Option<PositiveInteger>;
  fileSizeMax?: number;
  isNoteMandatory?: boolean;
  onDocumentCancel?: () => void;
} & CoApplicantInput;

export function ProofOfIncomeUploadSameDeviceFlow(props: Props) {
  const [firstImageSource, setFirstImageSource] = useState<Option<string>>(
    option.none
  );
  const [secondImageSource, setSecondImageSource] = useState<Option<string>>(
    option.none
  );
  const [flowState, setFlowState] = useState<FlowState>({
    id: "StartProcess",
  });
  const uploadProofOfIncomeDocumentMobile = useCommand(
    documentApi.uploadProofOfIncomeDocumentMobile
  );
  const uploadPOIByClient = useCommand(documentApi.uploadPOIByClient);

  const confirmPOIByClient = useCommand(loansApi.confirmPOIByClient);
  // const cancelUpload = useCommand(loansApi.cancelUploadByClient);

  const proofOfIncomeMortgageCompleteMobile = useCommand(
    documentApi.proofOfIncomeMortgageCompleteMobile
  );
  const proofOfIncomeCancel = useCommand(uploadApi.cancelProcess);

  const formatMessage = useFormatMessage();

  const uploadSingleDocument = (imgSource: string) =>
    pipe(
      FileContent.decode(imgSource.split(",")[1]),
      either.fold(
        () => taskEither.left("GenericError"),
        fileContent =>
          pipe(
            {
              productType: props.productType,
              parameters: props.parameters,
            },
            sequenceS(option.option),
            option.fold(
              () => taskEither.left("GenericError"),
              ({ productType, parameters }) => {
                if (productType === "PersonalProfile") {
                  return taskEither.left("GenericError");
                }

                return pipe(
                  parameters.docTypeId,
                  option.fold(
                    () => taskEither.left("GenericError"),
                    docTypeId =>
                      uploadProofOfIncomeDocumentMobile({
                        applicantIndex: pipe(
                          props.coApplicant,
                          option.fold(
                            () => "0",
                            coApplicant => coApplicant.index.toString()
                          )
                        ),
                        filenetDocumentType: "PROOF_OF_INCOME",
                        product: productType,
                        fileContent,
                        docTypeId,
                        applicationElementID: parameters.applicationElementID,
                      })
                  ),
                  taskEither.chain(({ status }) =>
                    status === "OK"
                      ? taskEither.right(status)
                      : taskEither.left<unknown>(genericError)
                  )
                );
              }
            )
          )
      )
    );

  const uploadTwoPageDocuments = (files: string[]) =>
    pipe(
      files,
      array.traverse(either.either)(file =>
        FileContent.decode(file.split(",")[1])
      ),
      either.fold(
        () => taskEither.left("GenericError"),
        fileContents =>
          pipe(
            {
              productType: props.productType,
              parameters: props.parameters,
            },
            sequenceS(option.option),
            option.fold(
              () => taskEither.left("GenericError"),
              ({ productType, parameters }) => {
                if (productType === "PersonalProfile") {
                  return taskEither.left("GenericError");
                }

                return pipe(
                  {
                    docTypeId: parameters.docTypeId,
                    applicationElementID: parameters.applicationElementID,
                  },
                  sequenceS(option.option),
                  option.fold(
                    () => taskEither.left("GenericError"),
                    parameters =>
                      fileContents[0] &&
                      uploadPOIByClient({
                        ...parameters,
                        filenetDocumentType: "PROOF_OF_INCOME",
                        product: productType,
                        firstPage: {
                          content: fileContents[0],
                          mimeType: "image/jpeg",
                        },
                        sameDevice: true,
                      })
                  ),
                  taskEither.chain(({ status }) =>
                    status === "OK"
                      ? taskEither.right(status)
                      : taskEither.left<unknown>(genericError)
                  )
                );
              }
            )
          )
      )
    );

  // SK should upload 2 photos, CZ just 1.
  const onUpload = () =>
    pipe(
      {
        productType: props.productType,
        firstImageSource,
      },
      sequenceS(option.option),
      option.fold(
        () => taskEither.left("GenericError"),
        ({ productType, firstImageSource }) =>
          pipe(
            productType,
            foldUploadFlowType({
              whenMortgage: () => uploadSingleDocument(firstImageSource),
              whenPersonalProfile: () => taskEither.left("GenericError"),
              whenStandardLoan: () =>
                uploadTwoPageDocuments([firstImageSource]),
            }),
            taskEither.mapLeft(() => "GenericError"),
            taskEither.chain(() =>
              pipe(
                productType,
                foldUploadFlowType({
                  whenMortgage: () => proofOfIncomeMortgageCompleteMobile(),
                  whenPersonalProfile: () => taskEither.left("GenericError"),
                  whenStandardLoan: () =>
                    taskEither.fromIO(() => setFlowState({ id: "AddNote" })),
                }),
                taskEither.mapLeft(() => "GenericError")
              )
            ),
            taskEither.chain(() =>
              taskEither.fromIO(() =>
                pipe(
                  productType,
                  foldUploadFlowType({
                    whenMortgage: () => setFlowState({ id: "UploadCompleted" }),
                    whenPersonalProfile: () =>
                      setFlowState({ id: "UploadCompleted" }),
                    whenStandardLoan: () => setFlowState({ id: "AddNote" }),
                  })
                )
              )
            )
          )
      )
    )();

  return foldState(flowState, {
    whenLoading: () => (
      <Box grow hAlignContent="center" vAlignContent="center">
        <FeedbackBlock
          type="loading"
          size="large"
          heading={formatMessage("Loading")}
          subheading={option.none}
        />
      </Box>
    ),
    whenStartProcess: () => (
      <StartProcess
        key="StartProcess"
        documentIdentificationFlow="Primary"
        onContinue={() => {
          setFlowState({ id: "UploadFirstSide" });
        }}
        isProofOfIncome
      />
    ),
    whenFirstPageUploaded: () => (
      <FeedbackBlock
        type="positive"
        size="large"
        heading={formatMessage(
          "Identification.UploadDocuments.proofOfIncome.firstPageUploaded.title"
        )}
        subheading={option.some(
          formatMessage(
            "Identification.UploadDocuments.proofOfIncome.firstPageUploaded.subtitle"
          )
        )}
        actions={[
          {
            variant: "primary",
            label: formatMessage(
              "Identification.UploadDocuments.proofOfIncome.firstPageUploaded.button.addSecondPage"
            ),
            action: () => setFlowState({ id: "UploadSecondSide" }),
          },
        ]}
      />
    ),
    whenUploadFirstSide: () => (
      <TakePhoto
        onContinue={imgSource =>
          setFlowState({
            id: "ConfirmData",
            imgSource,
            documentSide: "FirstSide",
          })
        }
      />
    ),
    whenUploadSecondSide: () => (
      <TakePhoto
        onContinue={imgSource =>
          setFlowState({
            id: "ConfirmData",
            imgSource,
            documentSide: "SecondSide",
          })
        }
      />
    ),
    whenConfirmData: ({ imgSource, documentSide }) => (
      <ConfirmPhoto
        imgSource={imgSource}
        onContinue={() => {
          foldDocumentSide(
            documentSide,
            () => setFirstImageSource(option.some(imgSource)),
            () => setSecondImageSource(option.some(imgSource))
          );

          setFlowState({ id: "ReviewAndUpload" });
        }}
        onRetake={() => {
          foldDocumentSide(
            documentSide,
            () => setFlowState({ id: "UploadFirstSide" }),
            () => setFlowState({ id: "UploadSecondSide" })
          );
        }}
      />
    ),
    whenReviewAndUpload: () => (
      <ReviewAndUpload
        firstImageSource={firstImageSource}
        secondImageSource={secondImageSource}
        onRetakePhoto={documentSide => {
          foldDocumentSide(
            documentSide,
            () => setFlowState({ id: "UploadFirstSide" }),
            () => setFlowState({ id: "UploadSecondSide" })
          );
        }}
        onOpenImage={documentSide => {
          foldDocumentSide(
            documentSide,
            () =>
              setFlowState({
                id: "ConfirmData",
                documentSide,
                imgSource: pipe(
                  firstImageSource,
                  option.getOrElse(() => "")
                ),
              }),
            () =>
              setFlowState({
                id: "ConfirmData",
                documentSide,
                imgSource: pipe(
                  secondImageSource,
                  option.getOrElse(() => "")
                ),
              })
          );
        }}
        onUpload={pipe(
          onUpload,
          taskEither.mapLeft(() => {
            setFlowState({ id: "UploadFailed" });
          })
        )}
        onUploadRejected={pipe(
          proofOfIncomeCancel(),
          taskEither.chain(() =>
            taskEither.fromIO(() => setFlowState({ id: "UploadRejected" }))
          )
        )}
      />
    ),
    whenAddNote: () => {
      return (
        <AddDocumentNote
          cancelProcess={() => {
            if (props.onDocumentCancel) {
              props.onDocumentCancel();
            }
            setFlowState({ id: "UploadRejected" });
          }}
          maxLengthNote={252 as PositiveInteger}
          goToUploadedSuccessfully={() => setFlowState({ id: "Sealing" })}
          onDocumentConfirm={note =>
            pipe(
              confirmPOIByClient({
                uploadNote: option.isSome(note) ? note.value : "",
              }),
              taskEither.mapLeft(
                () => "GenericError" as DocumentNoteUploadError
              ),
              taskEither.chain(({ status }) =>
                status === "OK"
                  ? taskEither.fromIO(() => setFlowState({ id: "Sealing" }))
                  : taskEither.leftIO(
                      () => "GenericError" as DocumentNoteUploadError
                    )
              )
            )
          }
          fileSizeMax={props.fileSizeMax ? props.fileSizeMax : 10}
          isNoteMandatory={props.isNoteMandatory}
        />
      );
    },
    whenUploadCompleted: () => (
      <MobileUploadFeedbackBanner state={MobileUploadState.Completed} />
    ),
    whenSealing: () => (
      <SealingProofOfIncome
        onError={() =>
          setFlowState({ id: "ConnectionLost", error: "GenericError" })
        }
        onDocumentSealed={() => setFlowState({ id: "UploadCompleted" })}
        parameters={props.parameters}
      />
    ),
    whenUploadRejected: () => (
      <MobileUploadFeedbackBanner state={MobileUploadState.RejectedByUser} />
    ),
    whenUploadFailed: () => (
      <MobileUploadFeedbackBanner state={MobileUploadState.UploadError} />
    ),
    whenConnectionLost: error => (
      <MobileUploadFeedbackBanner
        state={fromUploadErrorToFeedbackState(error)}
      />
    ),
  });
}
