import { useRef, useCallback } from "react";
import Webcam from "react-webcam";
import { Box } from "design-system";
import * as classes from "./TakePhoto.treat";

type Props = {
  onContinue: (imgSource: string) => void;
};

export const TakePhoto = (props: Props) => {
  const videoConstraints = {
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    facingMode: { ideal: "environment" },
  };

  const webcamRef = useRef<Webcam>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) props.onContinue(imageSrc);
    }
  }, [webcamRef]);

  // TODO @Mirel: check why TakePhoto.treat.ts is not applied correctly

  return (
    <>
      <Box hAlignContent="center" vAlignContent="center" column grow shrink>
        <Webcam
          style={{ position: "absolute", left: 0, top: 0 }}
          className={classes.webcam}
          audio={false}
          forceScreenshotSourceSize
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={window.innerWidth}
          videoConstraints={videoConstraints}
          onUserMedia={media => console.log("success", media)}
          onUserMediaError={mediaStreamError =>
            console.log("error", mediaStreamError)
          }
        />
        <Box
          onClick={capture}
          className={classes.captureButton}
          style={{
            position: "absolute",
            bottom: 10,
            left: "calc(50vw - 33px)",
            width: 66,
            height: 66,
            backgroundColor: "rgba(38, 38, 38, 0)",
            border: "5px solid rgba(38, 38, 38, 0.7)",
            borderRadius: 33,
          }}
          hAlignContent="center"
          vAlignContent="center"
        >
          <Box
            className={classes.captureButtonInside}
            style={{
              position: "relative",
              width: 50,
              height: 50,
              backgroundColor: "rgba(38, 38, 38, 0.7)",
              border: "5px solid rgba(38, 255, 38, 0)",
              borderRadius: 25,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
