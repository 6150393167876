import { useFormatMessage } from "../intl";
import { useCommand } from "../useAPI";
import { FilePDFDialog } from "../Common/Dialogs/FilePDFDialog/FilePDFDialog";
import * as documentAPI from "../Common/documentAPI";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";

type Props = {
  onDismiss: () => unknown;
};

export function BiometricConsentInfoDialog(props: Props) {
  const formatMessage = useFormatMessage();

  const personalDataProcessingTemplates = useCommand(documentAPI.templates);
  const personalDataProcessingContent = useCommand(documentAPI.content);

  return (
    <FilePDFDialog
      onDismiss={props.onDismiss}
      title={formatMessage(
        "PersonalDataProcessingDisclaimer.personalDataProcessingTitle" // TODO(gio): use the title for biometric consent
      )}
      file={pipe(
        personalDataProcessingTemplates({
          docsets: ["personalDataProcessing"], // TODO(gio): use the correct docset for biometric consent
        }),
        taskEither.chain(items =>
          personalDataProcessingContent({ docId: items[0].docId })
        )
      )}
    />
  );
}
