import {
  Box,
  Stack,
  Space,
  LocalizedString,
  unsafeLocalizedString,
} from "design-system";

type Props = {
  phoneNumber: string;
  text: LocalizedString;
};

const MobileDeviceText = ({ phoneNumber, text }: Props) => {
  return (
    <Stack column hAlignContent="center">
      {text}
      <Space units={2} />
      <Box>{unsafeLocalizedString(phoneNumber)}</Box>
    </Stack>
  );
};

export default MobileDeviceText;
