import { Box, FeedbackBlock } from "design-system";
import { option } from "fp-ts";
import { useFormatMessage } from "../../../intl";
import { usePollingEffect } from "../../../useAPI";
import * as documentApi from "../../../MortgageDashboard/Documents/api";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";

type Props = {
  onError: () => void;
  onDocumentSealed: () => void;
  parameters: Option<{
    docTypeId: Option<NonEmptyString>;
    applicationElementID: Option<NonEmptyString>;
  }>;
};

export const SealingProofOfIncome = (props: Props) => {
  const formatMessage = useFormatMessage();

  const docTypeId = pipe(
    props.parameters,
    option.fold(
      () => option.none,
      parameters => parameters.docTypeId
    ),
    option.fold(
      () => "",
      docTypeId => docTypeId
    )
  );

  const appElementId = pipe(
    props.parameters,
    option.fold(
      () => option.none,
      parameters => parameters.applicationElementID
    ),
    option.fold(
      () => "",
      appElementId => appElementId
    )
  );

  usePollingEffect(
    documentApi.ProofOfIncomeSealingStatus,
    {
      disabled: false,
      intervalMS: 5000,
      shouldPollingContinue: ({ status }) =>
        status === "unsealed" || status === "sealing",
      onError: () => props.onError(),
      onSuccess: ({ status }) => {
        if (status === "sealed") {
          props.onDocumentSealed();
        }
        if (status === "unsealed" || status === "sealing") {
          return;
        }
      },
    },
    {
      docTypeId: docTypeId as NonEmptyString,
      appElementId: appElementId as NonEmptyString,
    }
  );

  return (
    <Box grow hAlignContent="center" vAlignContent="center">
      <FeedbackBlock
        type="loading"
        size="large"
        heading={formatMessage("Uploading")}
        subheading={option.none}
      />
    </Box>
  );
};
