import { useState } from "react";
import { constant, pipe } from "fp-ts/function";
import { Task } from "fp-ts/Task";
import { Option } from "fp-ts/Option";
import { option, task, taskEither } from "fp-ts";
import { ClientDataCheck } from "./domain";
import { useCommand } from "../useAPI";
import * as idUploadApi from "../IdUpload/api";
import { CoApplicant, DocumentIdentificationFlow } from "../globalDomain";
import { RedoFraudCheckDialog } from "./RedoFraudCheckDialog";
import { MobileUploadStatusDialog } from "./MobileUploadStatusDialog";
import { Box, Heading, FeedbackDialog } from "design-system";
import { useFormatMessage } from "../intl";
import { MobileRecipientOptionType } from "./state";

type Props = {
  coApplicant: Option<CoApplicant>;
  fraudCheck: ClientDataCheck;
  recipient: MobileRecipientOptionType;
  documentIdentificationFlow: DocumentIdentificationFlow;
  canUploadAgain: boolean;
  onDismiss: () => unknown;
  onError: (reason: "GenericError" | "MaxAttemptsReached") => unknown;
  onAbort: () => unknown;
  onUploadAgain: () => unknown;
  onFraudCheck: (check: ClientDataCheck, canUploadAgain: boolean) => unknown;
  onContinue: () => unknown;
};

export function foldFraudCheckType<T>(
  check: ClientDataCheck,
  match: { [k in ClientDataCheck]: () => T }
) {
  return match[check]();
}

export function FraudCheckDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const fraudCheckResult = useCommand(idUploadApi.fraudCheckResult);

  const [status, setStatus] = useState<
    "FraudCheck" | "RedoMessage" | "Completed"
  >("FraudCheck");

  const handleFraudCheckResult = (check: ClientDataCheck): Task<unknown> => {
    return pipe(
      fraudCheckResult({
        zenIdPurpose: foldFraudCheckType(check, {
          SelfieFraudCheck: constant("FRAUD_CHECK_SELFIE"),
          HologramFraudCheck: constant("FRAUD_CHECK_HOLOGRAM"),
          HologramAndSelfieFraudCheck: constant(
            "FRAUD_CHECK_SELFIE_AND_HOLOGRAM"
          ),
        }),
      }),
      taskEither.fold(
        () => task.fromIO(() => props.onError("GenericError")),
        result => {
          switch (result) {
            case "Redo":
              return task.fromIO(() => setStatus("RedoMessage"));
            case "Abort":
              return task.fromIO(props.onAbort);
            case "UploadAgain":
              return task.fromIO(props.onUploadAgain);
            case "HologramAndSelfieFraudCheck":
            case "HologramFraudCheck":
            case "SelfieFraudCheck":
              return task.fromIO(() =>
                props.onFraudCheck(result, props.canUploadAgain)
              );
            case "Continue":
            case "ContinueWithWarning":
            case "EditDataWithWarning":
            case "EditData":
              return task.fromIO(() => setStatus("Completed"));
          }
        }
      )
    );
  };

  switch (status) {
    case "FraudCheck":
      return (
        <MobileUploadStatusDialog
          flowType={props.fraudCheck}
          recipient={props.recipient}
          onDismiss={props.onDismiss}
          onMaxAttemptsReached={() => props.onError("MaxAttemptsReached")}
          documentIdentificationFlow={props.documentIdentificationFlow}
          onUploadCompleted={handleFraudCheckResult(props.fraudCheck)}
          coApplicant={props.coApplicant}
          documentToUploadDetails={option.none}
        />
      );
    case "RedoMessage":
      return (
        <RedoFraudCheckDialog
          onContinue={task.fromIO(() => setStatus("FraudCheck"))}
          onUploadAgain={pipe(
            task.fromIO(props.onUploadAgain),
            option.fromPredicate(() => props.canUploadAgain)
          )}
        />
      );
    case "Completed":
      return (
        <FeedbackDialog
          type="success"
          title={formatMessage(
            "Identification.UploadDocuments.FraudChecksModal.success"
          )}
          cta={{
            label: formatMessage("Ok"),
            action: props.onContinue,
          }}
        >
          <Box hAlignContent="center">
            <Heading size="x-small" weight="regular">
              {formatMessage(
                "Identification.UploadDocuments.FraudChecksModal.successDescription"
              )}
            </Heading>
          </Box>
        </FeedbackDialog>
      );
  }
}
