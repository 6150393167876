import {
  Dialog,
  Loader,
  Box,
  MobileUploadIDIcon,
  ErrorBanner,
  Button,
  Stack,
  unsafeLocalizedString,
  Action,
  useDeviceUA,
  Space,
  useIsMobileLayout,
} from "design-system";
import { useQuery } from "../useAPI";
import * as uploadApi from "./api";
import { useFormatMessage } from "../intl";
import { option } from "fp-ts";
import { pipe, constNull, constant, constTrue } from "fp-ts/function";
import * as remoteData from "../RemoteData";
import MobileDeviceText from "./MobileDeviceText";
import { LinkPurpose, MobileRecipientType } from "./domain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useAppContext } from "../useAppContext";
import { MobileFlowType, MobileRecipientOptionType } from "./state";
import { Option } from "fp-ts/Option";
import { CoApplicantInput } from "../globalDomain";
import { foldMobileFlowType } from "./utils";
import { HelpLineSupportDocumentId } from "../Common/HelplineSupportDocumentId/HelplineSupportDocumentId";

type Props = {
  onDismiss: () => unknown;
  onSelectRecipient: (recipient: MobileRecipientOptionType) => unknown;
  onMockScannerUpload: Option<() => unknown>;
  onUploadFromSameDevice: () => unknown;
  mobileRecipientType: MobileRecipientType;
  type: MobileFlowType;
} & CoApplicantInput;

function foldType<T>(
  type: Props["type"],
  match: {
    [k in Props["type"]]: () => T;
  }
) {
  return match[type]();
}

export function RemoteMobileDeviceDialog(props: Props) {
  const { os, isIOS, isMobile } = useDeviceUA();
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();
  const [recipients] = useQuery(uploadApi.mobileRecipients, {
    coApplicant: props.coApplicant,
    linkPurpose: foldMobileFlowType<LinkPurpose>(props.type, {
      ProofOfIncome: constant("proofOfIncome"),
      DocumentUpload: constant("mobileIdUpload"),
      SelfieFraudCheck: constant("selfieFraudCheck"),
      HologramFraudCheck: constant("hologramFraudCheck"),
      HologramAndSelfieFraudCheck: constant("hologramAndSelfieFraudCheck"),
    }),
  });

  const isIOSNewerVersion =
    isIOS &&
    pipe(
      os.version,
      option.fold(
        constTrue,
        version => version.major <= 14 && version.minor < 3
      )
    );

  const showUploadFromSameDevice = isMobile && !isIOSNewerVersion;

  const {
    config: { mockScanner },
  } = useAppContext();

  const title = foldType(props.type, {
    DocumentUpload: constant(
      formatMessage("Identification.UploadDocuments.chooseMobileRecipientTitle")
    ),
    ProofOfIncome: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseProofOfIncomeMobileRecipientTitle"
      )
    ),
    SelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseSelfieMobileRecipientTitle"
      )
    ),
    HologramFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseHologramMobileRecipientTitle"
      )
    ),
    HologramAndSelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseSelfieAndHologramMobileRecipientTitle"
      )
    ),
  });

  const description = foldType(props.type, {
    DocumentUpload: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseMobileRecipientDescription"
      )
    ),
    ProofOfIncome: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseProofOfIncomeMobileRecipientDescription"
      )
    ),
    SelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
    HologramFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
    HologramAndSelfieFraudCheck: constant(
      formatMessage(
        "Identification.UploadDocuments.chooseFraudCheckMobileRecipientDescription"
      )
    ),
  });

  const sendLinkAction = (recipient: MobileRecipientOptionType): Action => ({
    variant: showUploadFromSameDevice ? "text" : "primary",
    label: formatMessage("Identification.UploadDocuments.sendLink"),
    action: () => props.onSelectRecipient(recipient),
  });

  const actions: React.ComponentProps<typeof Dialog>["actions"] = pipe(
    recipients,
    remoteData.fold(constant([]), constant([]), recipients =>
      pipe(
        recipients[props.mobileRecipientType].phoneNumber,
        option.fold<NonEmptyString, [] | [Action] | [Action, Action]>(
          constant([]),
          phoneNumber =>
            showUploadFromSameDevice
              ? [
                  sendLinkAction({
                    key: props.mobileRecipientType,
                    phoneNumber,
                    disabled: false,
                  }),
                  {
                    variant: "primary",
                    label: formatMessage(
                      "Identification.UploadDocuments.uploadFromThisDevice"
                    ),
                    action: props.onUploadFromSameDevice,
                  },
                ]
              : [
                  sendLinkAction({
                    key: props.mobileRecipientType,
                    phoneNumber,
                    disabled: false,
                  }),
                ]
        )
      )
    )
  );
  return (
    <Dialog
      size="medium"
      variant="center"
      title={title}
      subtitle={description}
      actions={actions}
      onDismiss={option.some(props.onDismiss)}
      icon={MobileUploadIDIcon}
    >
      <Stack column grow shrink units={10}>
        {pipe(
          recipients,
          remoteData.fold(
            () => (
              <Box hAlignContent="center" vAlignContent="center">
                <Loader />
              </Box>
            ),
            constNull,
            recipients =>
              pipe(
                recipients[props.mobileRecipientType].phoneNumber,
                option.fold(
                  () => (
                    <ErrorBanner>
                      {formatMessage(
                        "Identification.UploadDocuments.errorPhoneNumber"
                      )}
                    </ErrorBanner>
                  ),
                  phoneNumber => (
                    <Box column hAlignContent="center">
                      <MobileDeviceText
                        phoneNumber={phoneNumber}
                        text={
                          props.mobileRecipientType === "Client"
                            ? formatMessage(
                                "Identification.UploadDocuments.clientPhoneNumber"
                              )
                            : formatMessage(
                                "Identification.UploadDocuments.brokerPhoneNumber"
                              )
                        }
                      />
                      {isMobileLayout && (
                        <>
                          <Space units={5} />
                          <HelpLineSupportDocumentId />
                        </>
                      )}
                    </Box>
                  )
                )
              )
          )
        )}
        {mockScanner &&
          pipe(
            props.onMockScannerUpload,
            option.fold(constNull, onMockScannerUpload => (
              <Button
                variant="secondary"
                size="default"
                label={unsafeLocalizedString("Mock scanner")}
                action={onMockScannerUpload}
              />
            ))
          )}
      </Stack>
    </Dialog>
  );
}
