import { useFormatMessage } from "../intl";
import { taskEither } from "fp-ts";
import { FileImageDialog } from "../Common/Dialogs/FileImageDialog";
import { CompressedFileContent } from "../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

export type DocumentPreview = {
  fileName: NonEmptyString;
  fileContent: CompressedFileContent;
};

type Props = {
  document: DocumentPreview;
  onDismiss: () => unknown;
};

export function DocumentPreviewDialog(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <FileImageDialog
      file={taskEither.of(props.document.fileContent)}
      title={formatMessage(
        "Identification.UploadDocuments.DocumentModal.title"
      )}
      onDismiss={props.onDismiss}
      fileName={props.document.fileName}
    />
  );
}
