import { UploadDocumentFlowType } from "../../../globalDomain";

export type DocumentSide = "FirstSide" | "SecondSide";

export function foldDocumentSide<T>(
  documentSide: DocumentSide,
  onFirstSide: () => T,
  onSecondSide: () => T
) {
  switch (documentSide) {
    case "FirstSide":
      return onFirstSide();
    case "SecondSide":
      return onSecondSide();
  }
}

export function foldUploadFlowType<T>(match: {
  whenMortgage: () => T;
  whenStandardLoan: () => T;
  whenPersonalProfile: () => T;
}) {
  return function (uploadDocumentFlowType: UploadDocumentFlowType) {
    switch (uploadDocumentFlowType) {
      case "MORTGAGE":
        return match.whenMortgage();
      case "CL":
      case "RL":
      case "RPL":
      case "SL":
      case "TL":
        return match.whenStandardLoan();
      case "PersonalProfile":
        return match.whenPersonalProfile();
    }
  };
}

export const ratio = 1920 / 1080;
